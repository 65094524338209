import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";

const fileTypes = ["JPG", "PNG", "JPEG", "MP4", "MOV"];
//DragDrop is separate just in case it becomes unweildy. in might end up being simple enough to just be in the file upload form.
export function DragDrop({ handleFileChange }) {
  // const [file, setFile] = useState(null);
  // const handleChange = (file) => {
  //   console.log(file);
  //   setFile(file);
  // };
  return (
    <FileUploader
      onDrop={(file) => {
        console.log(file);
        // const video = document.createElement("VIDEO");
        // video.src = file.preview;
        // video.addEventListener("loadedmetadata", () => {
        //   console.log(video.duration);
        // });
      }}
      handleChange={handleFileChange}
      name="file"
      types={fileTypes}
      // classes="drop-area"
      classes="admin-thumbnail-drop-zone"
    />
  );
}
