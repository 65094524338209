import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { AdminTable, Uploader } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";

const initialCategoryForm = {
  name: "",
  //   parent_trainer_category_id: 0,
};

export function AdminFixCategories() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //categories
  const [categoryList, setCategoryList] = useState();
  const [categoryListIds, setCategoryListIds] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [editingCategory, setEditingCategory] = useState();

  //form
  const [categoryForm, setCategoryForm] = useState(initialCategoryForm);

  const getCategories = () => {
    HandyGolfAPI.sendRequest("fix/category/list")
      .then((data) => {
        // console.log(data);
        let cats = data;
        let catIds = [];
        cats.forEach((cat) => catIds.push(cat.fix_cause_category_id));
        setCategoryListIds(catIds);
        setCategoryList(cats);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedCategory) {
      getCategories();
    }
  }, [selectedCategory]);
  const handleCategoryOrderChange = (direction, categoryId) => {
    let tempIdsArray = categoryListIds;
    let tempCategoryList = categoryList;
    let currentIndex = categoryListIds.findIndex((id) => id === categoryId);
    let idElement = categoryListIds[currentIndex];
    let childElement = categoryList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempCategoryList.splice(currentIndex, 1);
      tempCategoryList.splice(currentIndex - 1, 0, childElement);
      setCategoryList(tempCategoryList);
      setCategoryListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempCategoryList.splice(currentIndex, 1);
      tempCategoryList.splice(currentIndex + 1, 0, childElement);
      setCategoryList(tempCategoryList);
      setCategoryListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };

  const handleCategoryFormChange = (e) => {
    setCategoryForm({ ...categoryForm, [e.target.name]: e.target.value });
  };

  const handleCategoryCreateSubmit = (e) => {
    e.preventDefault();
    let createdCat = {
      ...categoryForm,
      sort_order: categoryList.length + 1,
    };
    HandyGolfAPI.sendRequest("fix/category/create", {
      fix_cause_category: createdCat,
    })
      .then((data) => {
        let catList = categoryList;
        let newCat = data.data;
        catList.push(newCat);
        setCategoryList(catList);
        setCategoryForm(initialCategoryForm);
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCategoryEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("fix/category/update", {
      fix_cause_category_id: selectedCategory.fix_cause_category_id,
      fix_cause_category: categoryForm,
    })
      .then((data) => {
        setSelectedCategory(data.data);
        setCategoryForm(initialCategoryForm);
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              <div className="admin-action">
                <div className="admin-action-view">
                  {categoryList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        Fix Categories
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Category</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={categoryList}
                        tableIds={categoryListIds}
                        handleOrderChange={handleCategoryOrderChange}
                        headers={
                          openSorting ? ["", "sort_order", "name"] : ["name"]
                        }
                        setSortChanging={setSortChanging}
                        idType={"fix_cause_category_id"}
                        setSelectedItem={setSelectedCategory}
                        setEditingItem={setEditingCategory}
                        // typeConverter={videoTypeConverter}
                      />
                      {openSorting ? (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(false);
                              }}
                            >
                              <strong>Cancel</strong>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                HandyGolfAPI.sendRequest(
                                  "fix/category/reorderCategories",
                                  {
                                    fix_cause_category_ids: categoryListIds,
                                  }
                                ).then((data) => {
                                  getCategories();
                                  setOpenSorting(false);
                                });
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Save Sort Order</strong>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(true);
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Set Sort Order</strong>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setCategoryForm(initialCategoryForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Category</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleCategoryCreateSubmit}
                        >
                          <div>
                            <label>Category Name</label>
                            <input
                              type="text"
                              name="name"
                              value={categoryForm.name}
                              placeholder="category name here"
                              onChange={handleCategoryFormChange}
                            />
                          </div>

                          <Button fluid primary>
                            Submit Category
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !editingCategory
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingCategory(false);
                          setSelectedCategory();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedCategory && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="admin-info-half"
                            style={{ maxWidth: "460px" }}
                          >
                            <div className="admin-info-half__infobox">
                              <div className="admin-info-half__section-title">
                                {selectedCategory.name} Category
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setEditing(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit</strong>
                                </div>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Name:</label>
                                <strong>{selectedCategory.name}</strong>
                              </div>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this FIX CATEGORY and all of its contents?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "fix/category/delete",
                                      {
                                        fix_cause_category_id:
                                          selectedCategory.fix_cause_category_id,
                                      }
                                    ).then((data) => {
                                      console.log(data);
                                      setEditingCategory(false);
                                      setSelectedCategory();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setCategoryForm(initialCategoryForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit <br></br>'{selectedCategory.name}' Category
                              </h2>
                              <form
                                onSubmit={handleCategoryEditSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedCategory.name}
                                    value={categoryForm.name}
                                    onChange={handleCategoryFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Edit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
