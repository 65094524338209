import React, { useEffect, useState } from "react";

// Handy Golf Libs
import HandyGolf from "../lib/HandyGolf";
import HandyGolfAuth from "../lib/HandyGolfAuth";
import { useTheme } from "../providers";
// Materials
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "./shared";
import MButton from "@mui/material/Button";

// Router
import {
  Routes,
  Route,
  NavLink,
  Link,
  BrowserRouter,
  useLocation,
} from "react-router-dom";

import { useUser } from "../providers";

export function AdminNavItem({ title, subNav, soloUrl, selectedNav }) {
  // const [currentPath, setCurrentPath] = useState()

  // useEffect(()=>{

  // },[])

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {subNav ? (
        <>
          <MButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={
              title === selectedNav
                ? { color: "#3ab549" }
                : { color: "#707070", fontWeight: "normal" }
            }
          >
            <div className="admin-navigation__navlink">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "16px",
                }}
              >
                {title}{" "}
                {open ? (
                  <i className="material-icons open">chevron_right</i>
                ) : (
                  <i className="material-icons">chevron_right</i>
                )}
              </div>
            </div>

            {/* {title} */}
            {/* ▼ */}
          </MButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {subNav.map((nav, idx) => {
              return (
                <MenuItem
                  onClick={handleClose}
                  key={idx}
                  style={{ padding: "0", width: "100%" }}
                >
                  <div className="admin-navigation__navlink">
                    <NavLink to={nav.url}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "16px",
                          padding: "8px 16px",
                        }}
                      >
                        {nav.name}
                      </div>
                    </NavLink>
                  </div>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      ) : (
        <>
          <MButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ padding: "0" }}
          >
            <div className="admin-navigation__navlink">
              <NavLink to={soloUrl}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "16px",
                    padding: "8px 16px",
                  }}
                >
                  {title}
                </div>
              </NavLink>
            </div>
          </MButton>
        </>
      )}
    </>
  );
}
