import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import Webcam from "react-webcam";
import HandyGolfAPI from "../lib/HandyGolfAPI";
import { useUser } from "../providers";
import {
  Card,
  Container,
  AppTopBar,
  BottomNav,
  Player,
  AdminNav,
} from "../components";

import {
  StateContext,
  StateDispatchContext,
  CheckCacheContext,
} from "../context/AppContext";

export function AdminDash() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  let navigate = useNavigate();
  const image =
    "https://visitlaketahoe.com/wp-content/uploads/2020/12/Man-golfing-on-course.jpg";
  return (
    <>
      <AdminNav />
      {/* <AppTopBar pageTitle={"Admin Dashboard"} /> */}
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <>
              this is mostly just place holder until we want something else here
            </>
            <div className="card-container">
              <Card
                borderless
                title="Coaches"
                description=""
                image={image}
                onClick={() => {
                  navigate("/admin/coaches");
                }}
              />
              <Card
                borderless
                title="Equipment"
                description=""
                image={image}
                onClick={() => {
                  navigate("/admin/equipment");
                }}
              />
              <Card
                borderless
                title="Lessons"
                description=""
                image={image}
                onClick={() => {
                  navigate("/admin/lessons");
                }}
              />
              <Card
                borderless
                title="Programs"
                description=""
                image={image}
                onClick={() => {
                  navigate("/admin/programs");
                }}
              />

              <Card
                borderless
                title="Videos"
                description=""
                image={image}
                onClick={() => {
                  navigate("/admin/trainer/videos");
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="container__side-content--admin"></div> */}
      </div>
    </>
  );
}
