import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { AdminTable, Uploader } from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";

const initialLessonForm = {
  name: "",
  tagline: "",
  type: 1,
  // sort_order: 0,
  description: "",
};
const initialUnitForm = {
  name: "",
  description: "",
  text: "",
  unit_type: 1,
  // sort_order:0
};
export function AdminLessons() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const [loading, setLoading] = useState(true);
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();

  //lessons
  const [lessonList, setLessonList] = useState();
  const [lessonListIds, setLessonListIds] = useState();
  const [selectedLesson, setSelectedLesson] = useState();
  const [editingLesson, setEditingLesson] = useState();
  //lesson units
  const [lessonUnitList, setLessonUnitList] = useState();
  const [lessonUnitListIds, setLessonUnitListIds] = useState();
  const [selectedLessonUnit, setSelectedLessonUnit] = useState();
  const [editingLessonUnit, setEditingLessonUnit] = useState();
  //lesson sub units
  const [lessonSubUnitList, setLessonSubUnitList] = useState();
  const [lessonSubUnitListIds, setLessonSubUnitListIds] = useState();
  const [selectedSubUnit, setSelectedSubUnit] = useState();
  const [editingSubUnit, setEditingSubUnit] = useState();

  //updating / editing states
  const [lessonForm, setLessonForm] = useState(initialLessonForm);
  const [unitForm, setUnitForm] = useState(initialUnitForm);
  //maybe separate subUnit form if we need

  const getLessons = () => {
    HandyGolfAPI.sendRequest("lesson/lesson/list").then((data) => {
      initialLessonIdsArray(data);
      setLessonList(data);
      setLoading(false);
    });
  };
  const initialLessonIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_id));
    setLessonListIds(idsArray);
  };

  const getLessonUnits = () => {
    HandyGolfAPI.sendRequest("lesson/lesson_unit/list").then((data) => {
      let currentLessonUnits = data.filter(
        (unit) =>
          unit.lesson_id === selectedLesson.lesson_id &&
          unit.parent_lesson_unit_id === 0
      );
      console.log(currentLessonUnits);
      initialLessonUnitIdsArray(currentLessonUnits);
      setLessonUnitList(currentLessonUnits);
      // setAllNonParentSubUnits(allSubUnits);
    });
  };
  const getLessonSubUnits = () => {
    HandyGolfAPI.sendRequest("lesson/lesson_unit/list").then((data) => {
      let allSubUnits = data.filter(
        (unit) =>
          unit.lesson_id === selectedLesson.lesson_id &&
          unit.parent_lesson_unit_id !== 0
      );
      let currentSubUnits = allSubUnits.filter(
        (sub) => sub.parent_lesson_unit_id === selectedLessonUnit.lesson_unit_id
      );
      initialSubUnitIdsArray(currentSubUnits);
      setLessonSubUnitList(currentSubUnits);
    });
  };

  const initialLessonUnitIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_unit_id));
    setLessonUnitListIds(idsArray);
  };

  const initialSubUnitIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.lesson_unit_id));
    setLessonSubUnitListIds(idsArray);
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedLesson) {
      getLessons();
    }
  }, [selectedLesson]);

  useEffect(() => {
    if (selectedLesson && !selectedLessonUnit) {
      getLessonUnits();
    }
  }, [selectedLesson]);

  useEffect(() => {
    if (selectedLessonUnit) {
      getLessonSubUnits();
    }
  }, [selectedLessonUnit]);

  const handleLessonOrderChange = (direction, lessonId) => {
    let tempIdsArray = lessonListIds;
    let tempLessons = lessonList;
    let currentIndex = lessonListIds.findIndex((id) => id === lessonId);
    let idElement = lessonListIds[currentIndex];
    let lessonElement = lessonList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessons.splice(currentIndex, 1);
      tempLessons.splice(currentIndex - 1, 0, lessonElement);
      setLessonUnitList(tempLessons);
      setLessonUnitListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessons.splice(currentIndex, 1);
      tempLessons.splice(currentIndex + 1, 0, lessonElement);
      setLessonUnitList(tempLessons);
      setLessonUnitListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleLessonUnitOrderChange = (direction, lessonUnitId) => {
    let tempIdsArray = lessonUnitListIds;
    let tempLessonUnits = lessonUnitList;
    let currentIndex = lessonUnitListIds.findIndex((id) => id === lessonUnitId);
    let idElement = lessonUnitListIds[currentIndex];
    let lessonUnitElement = lessonUnitList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex - 1, 0, lessonUnitElement);
      setLessonUnitList(tempLessonUnits);
      setLessonUnitListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex + 1, 0, lessonUnitElement);
      setLessonUnitList(tempLessonUnits);
      setLessonUnitListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleSubUnitOrderChange = (direction, lessonSubUnitId) => {
    let tempIdsArray = lessonSubUnitListIds;
    let tempLessonUnits = lessonSubUnitList;
    let currentIndex = lessonSubUnitListIds.findIndex(
      (id) => id === lessonSubUnitId
    );
    let idElement = lessonSubUnitListIds[currentIndex];
    let lessonSubUnitElement = lessonSubUnitList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex - 1, 0, lessonSubUnitElement);
      setLessonSubUnitList(tempLessonUnits);
      setLessonSubUnitListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempLessonUnits.splice(currentIndex, 1);
      tempLessonUnits.splice(currentIndex + 1, 0, lessonSubUnitElement);
      setLessonSubUnitList(tempLessonUnits);
      setLessonSubUnitListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };

  const handleLessonFormChange = (e) => {
    setLessonForm({
      ...lessonForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleLessonEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("lesson/lesson/update", {
      lesson_id: selectedLesson.lesson_id,
      lesson: lessonForm,
    }).then((data) => {
      console.log(data);
      setSelectedLesson(data.data);
      // setEditingLesson(false);
      setEditing(false);
      setLessonForm(initialLessonForm);
    });
  };

  const handleLessonCreateSubmit = (e) => {
    e.preventDefault();
    let newSortOrder = lessonList.length + 1;
    let lessonFormWithSort = lessonForm;
    lessonFormWithSort = { ...lessonFormWithSort, sort_order: newSortOrder };

    HandyGolfAPI.sendRequest("lesson/lesson/create", {
      lesson: lessonFormWithSort,
    }).then((data) => {
      console.log(data);
      //take data.data as var
      //add it to end of list
      //add its id to end of listIds
      let newLesson = data.data;
      let lessons = lessonList;
      let lessonIds = lessonListIds;
      lessons.push(newLesson);
      lessonIds.push(newLesson.lesson_id);
      setLessonList(lessons);
      setLessonListIds(lessonIds);
      setCreating(false);
      setLessonForm(initialLessonForm);
    });
  };

  const handleUnitFormChange = (e) => {
    setUnitForm({
      ...unitForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleUnitEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
      lesson_unit_id: selectedSubUnit
        ? selectedSubUnit.lesson_unit_id
        : selectedLessonUnit.lesson_unit_id,
      lesson_unit: unitForm,
    }).then((data) => {
      console.log(data);
      if (selectedSubUnit) {
        setSelectedSubUnit(data.data);
        // setEditingSubUnit(false);
      } else {
        setSelectedLessonUnit(data.data);
        // setEditingLessonUnit(false);
      }

      setEditing(false);
      setUnitForm(initialUnitForm);
    });
  };
  const handleUnitCreateSubmit = (e) => {
    e.preventDefault();
    let newSortOrder = selectedLessonUnit
      ? lessonSubUnitList.length + 1
      : lessonUnitList.length + 1;
    let parentId = selectedLessonUnit ? selectedLessonUnit.lesson_unit_id : 0;
    let unitFormWithSortAndId = unitForm;
    unitFormWithSortAndId = {
      ...unitFormWithSortAndId,
      sort_order: newSortOrder,
      lesson_id: selectedLesson.lesson_id,
      parent_lesson_unit_id: parentId,
    };
    console.log(unitFormWithSortAndId);
    HandyGolfAPI.sendRequest("lesson/lesson_unit/create", {
      lesson_unit: unitFormWithSortAndId,
    })
      .then((data) => {
        console.log(data);
        if (selectedSubUnit) {
          console.log("i dont think its possible to be here but just in case");
        }
        if (selectedLesson && !selectedLessonUnit) {
          let newParentUnit = data.data;
          let parentUnits = lessonUnitList;
          let parentUnitIds = lessonUnitListIds;
          parentUnits.push(newParentUnit);
          parentUnitIds.push(newParentUnit.lesson_unit_id);
          setLessonUnitList(parentUnits);
          setLessonUnitListIds(parentUnitIds);
        }
        if (selectedLessonUnit) {
          let newSubUnit = data.data;
          let subUnits = lessonSubUnitList;
          let subUnitIds = lessonSubUnitListIds;
          subUnits.push(newSubUnit);
          subUnitIds.push(newSubUnit.lesson_unit_id);
          setLessonSubUnitList(subUnits);
          setLessonSubUnitListIds(subUnitIds);
        }

        setCreating(false);
        setUnitForm(initialUnitForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addUnitNumberColumn = (units) => {
    return units.map((unit, idx) => ({
      ...unit,
      Unit: idx + 1,
    }));
  };
  const addSubUnitColumn = (subUnits) => {
    return subUnits.map((sub, idx) => ({
      ...sub,
      Unit: `${selectedLessonUnit.sort_order}.${idx + 1}`,
    }));
  };
  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              {/* <h3>Dynamic Title</h3> */}
              <div className="admin-action">
                <div className="admin-action-view">
                  {lessonList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        HandyGolf Lessons
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Lesson</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={lessonList}
                        tableIds={lessonListIds}
                        handleOrderChange={handleLessonOrderChange}
                        headers={
                          openSorting
                            ? ["", "sort_order", "name"]
                            : ["name", "description"]
                        }
                        setSortChanging={setSortChanging}
                        idType={"lesson_id"}
                        setSelectedItem={setSelectedLesson}
                        setEditingItem={setEditingLesson}
                      />
                      {openSorting ? (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(false);
                              }}
                            >
                              <strong>Cancel</strong>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                HandyGolfAPI.sendRequest(
                                  "lesson/lesson/reorderLessons",
                                  {
                                    lesson_ids: lessonListIds,
                                  }
                                ).then((data) => {
                                  getLessons();
                                });
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Save Sort Order</strong>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(true);
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Set Sort Order</strong>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setLessonForm(initialLessonForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Make a New Lesson</h2>
                        <form
                          onSubmit={handleLessonCreateSubmit}
                          className="admin-form"
                        >
                          <div>
                            <label>Lesson Name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="name for new lesson"
                              value={lessonForm.name}
                              onChange={handleLessonFormChange}
                            />
                          </div>
                          <div>
                            <label>Tagline</label>
                            <input
                              type="text"
                              name="tagline"
                              placeholder="tagline for new lesson"
                              value={lessonForm.tagline}
                              onChange={handleLessonFormChange}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label>Select Type for Lesson</label>
                            <div
                              style={{
                                display: "flex",
                                gap: "2rem",
                              }}
                            >
                              <div className="admin-radio-button">
                                <input
                                  type="radio"
                                  name="type"
                                  id="free"
                                  value="free"
                                  checked={lessonForm.type === 1}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    const numericValue =
                                      value === "free"
                                        ? 1
                                        : value === "subscription"
                                        ? 2
                                        : value;
                                    setLessonForm({
                                      ...lessonForm,
                                      [name]: numericValue,
                                    });
                                  }}
                                />
                                <label>Free</label>
                              </div>
                              <div className="admin-radio-button">
                                <input
                                  type="radio"
                                  name="type"
                                  id="subscription"
                                  value="subscription"
                                  checked={lessonForm.type === 2}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    const numericValue =
                                      value === "free"
                                        ? 1
                                        : value === "subscription"
                                        ? 2
                                        : value;
                                    setLessonForm({
                                      ...lessonForm,
                                      [name]: numericValue,
                                    });
                                  }}
                                />
                                <label>Subscription</label>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label>Description</label>
                            <input
                              type="text"
                              name="description"
                              placeholder="description for new lesson"
                              value={lessonForm.description}
                              onChange={handleLessonFormChange}
                            />
                          </div>
                          <div>
                            <label>
                              To Edit Sort Order Go back to the lesson list
                            </label>
                            {/* <input
                                    type="number"
                                    name="sort_order"
                                    disabled
                                    placeholder="sort order"
                                   
                                  /> */}
                          </div>
                          <Button fluid primary>
                            Submit Changes
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !editingLesson
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingLesson(false);
                          setSelectedLesson();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedLesson && (
                      <>
                        <div className="admin-info-half">
                          <div className="admin-info-half__title">
                            Lesson Details
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                // setLessonForm(selectedLesson);
                                setEditing(true);
                              }}
                            >
                              <i className="material-icons">edit</i>
                              <strong>Edit</strong>
                            </div>
                          </div>
                          <div className="admin-info-half__thumbnailbox">
                            <span>Lesson Thumbnail</span>
                            <div style={{ position: "relative" }}>
                              {!uploading && (
                                <div
                                  className="admin-edit-thumbnail-button"
                                  onClick={() => {
                                    setUploading(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit Thumbnail</strong>
                                </div>
                              )}

                              <img
                                src={
                                  selectedLesson.thumbnail_url !== ""
                                    ? selectedLesson.thumbnail_url
                                    : "/img/thumbnail-absent.png"
                                }
                                style={
                                  uploading
                                    ? { opacity: ".5", transition: ".2s" }
                                    : { opacity: "1", transition: ".2s" }
                                }
                              />
                              {uploading && (
                                <>
                                  <div
                                    className="admin-edit-thumbnail-button"
                                    onClick={() => {
                                      setUploading(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                  <Uploader
                                    folder={"lesson_thumbnails/"}
                                    fileName={`lesson_thumbnails/lesson_${selectedLesson.lesson_id}`}
                                    setUploading={setUploading}
                                    setNew={setSelectedLesson}
                                    id={selectedLesson.lesson_id}
                                    type={"lesson"}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="admin-info-half__infobox">
                            <div className="admin-info-half__key-value">
                              <label>Name:</label>
                              <strong>{selectedLesson.name}</strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Tagline:</label>
                              <strong>{selectedLesson.tagline}</strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Type:</label>
                              <strong>
                                {selectedLesson.type === 1
                                  ? "Free"
                                  : "Subscription"}
                              </strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Sort Order:</label>
                              <strong>{selectedLesson.sort_order}</strong>
                            </div>

                            <div className="admin-info-half__description">
                              <label>Description:</label>
                              <div>
                                {selectedLesson.description !== ""
                                  ? selectedLesson.description
                                  : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this lesson and all of its contents?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "lesson/lesson/delete",
                                      { lesson_id: selectedLesson.lesson_id }
                                    ).then((data) => {
                                      console.log(data);
                                      setEditingLesson(false);
                                      setSelectedLesson();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        {lessonUnitList && (
                          <div className="admin-table-half">
                            <div className="admin-table-half__title">
                              Units in Selected Lesson
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  // setEditing(true);
                                  setCreating(true);
                                }}
                              >
                                <i className="material-icons">add</i>
                                <strong>New Unit</strong>
                              </div>
                            </div>
                            <AdminTable
                              tableData={addUnitNumberColumn(lessonUnitList)}
                              tableIds={lessonUnitListIds}
                              handleOrderChange={handleLessonUnitOrderChange}
                              // TODO probably a better way to do the 1.1,1.2 2.1 thing
                              headers={
                                openSorting
                                  ? ["", "sort_order", "name"]
                                  : ["Unit", "name", "description"]
                              }
                              setSortChanging={setSortChanging}
                              idType={"lesson_unit_id"}
                              setSelectedItem={setSelectedLessonUnit}
                              setEditingItem={setEditingLessonUnit}
                            />

                            {openSorting ? (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "lesson/lesson_unit/reorderLessonUnits",
                                        {
                                          lesson_id: selectedLesson.lesson_id,
                                          parent_lesson_unit_id: 0,
                                          lesson_unit_ids: lessonUnitListIds,
                                        }
                                      ).then((data) => {
                                        getLessonUnits();
                                      });
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Save Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(true);
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Set Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={
                            !creating
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCreating(false);
                                setUnitForm(initialUnitForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                New Unit For:<br></br>'{selectedLesson.name}'
                                Lesson
                              </h2>
                              <form
                                onSubmit={handleUnitCreateSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <label>Unit Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="name for a new unit"
                                    value={unitForm.name}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder="description of new unit"
                                    value={unitForm.description}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Unit Text</label>
                                  <input
                                    type="text"
                                    name="text"
                                    placeholder="Text info of new unit"
                                    value={unitForm.text}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>Select Unit Type</label>
                                  <div style={{ display: "flex", gap: "2rem" }}>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="normal lesson unit"
                                        value="normal lesson unit"
                                        checked={unitForm.unit_type === 1}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Normal Lesson Unit</label>
                                    </div>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="drill lesson unit"
                                        value="drill lesson unit"
                                        checked={unitForm.unit_type === 2}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Drill Lesson Unit</label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label>
                                    To edit unit sort order go back to previous
                                    page
                                  </label>
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setLessonForm(initialLessonForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>Edit Lesson Form</h2>
                              <form
                                onSubmit={handleLessonEditSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <label>Lesson Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedLesson.name}
                                    value={lessonForm.name}
                                    onChange={handleLessonFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Tagline</label>
                                  <input
                                    type="text"
                                    name="tagline"
                                    placeholder={selectedLesson.tagline}
                                    value={lessonForm.tagline}
                                    onChange={handleLessonFormChange}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>Select Type for Lesson</label>
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "2rem",
                                    }}
                                  >
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="type"
                                        id="free"
                                        value="free"
                                        checked={lessonForm.type === 1}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "free"
                                              ? 1
                                              : value === "subscription"
                                              ? 2
                                              : value;
                                          setLessonForm({
                                            ...lessonForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Free</label>
                                    </div>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="type"
                                        id="subscription"
                                        // placeholder="type number for new lesson"
                                        value="subscription"
                                        checked={lessonForm.type === 2}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "free"
                                              ? 1
                                              : value === "subscription"
                                              ? 2
                                              : value;
                                          setLessonForm({
                                            ...lessonForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Subscription</label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder={selectedLesson.description}
                                    value={lessonForm.description}
                                    onChange={handleLessonFormChange}
                                  />
                                </div>
                                <div>
                                  <label>
                                    To Edit Sort Order Go back to the lesson
                                    list
                                  </label>
                                  {/* <input
                                    type="number"
                                    name="sort_order"
                                    disabled
                                    placeholder="sort order"
                                   
                                  /> */}
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      !editingLessonUnit
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingLessonUnit(false);
                          setSelectedLessonUnit();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedLessonUnit && (
                      <>
                        <div className="admin-info-half">
                          <div className="admin-info-half__title">
                            Lesson Unit Details
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                // setLessonForm(selectedLesson);
                                setEditing(true);
                              }}
                            >
                              <i className="material-icons">edit</i>
                              <strong>Edit</strong>
                            </div>
                          </div>

                          <div className="admin-info-half__thumbnailbox">
                            <span>Lesson Unit Thumbnail</span>
                            <div style={{ position: "relative" }}>
                              {!uploading && (
                                <div
                                  className="admin-edit-thumbnail-button"
                                  onClick={() => {
                                    setUploading(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit Thumbnail</strong>
                                </div>
                              )}

                              <img
                                src={
                                  selectedLessonUnit.thumbnail_url !== ""
                                    ? selectedLessonUnit.thumbnail_url
                                    : "/img/thumbnail-absent.png"
                                }
                                style={
                                  uploading
                                    ? { opacity: ".5", transition: ".2s" }
                                    : { opacity: "1", transition: ".2s" }
                                }
                              />
                              {uploading && (
                                <>
                                  <div
                                    className="admin-edit-thumbnail-button"
                                    onClick={() => {
                                      setUploading(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                  <Uploader
                                    folder={"lesson_unit_thumbnails/"}
                                    fileName={`lesson_unit_thumbnails/lesson_unit_${selectedLessonUnit.lesson_unit_id}`}
                                    setUploading={setUploading}
                                    setNew={setSelectedLessonUnit}
                                    id={selectedLessonUnit.lesson_unit_id}
                                    type={"lesson_unit"}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="admin-info-half__infobox">
                            <div className="admin-info-half__key-value">
                              <label>Name:</label>
                              <strong>{selectedLessonUnit.name}</strong>
                            </div>

                            <div className="admin-info-half__key-value">
                              <label>Unit Type:</label>
                              <strong>
                                {selectedLessonUnit.unit_type === 1
                                  ? "Normal Lesson Unit"
                                  : "Drill Lesson Unit"}
                              </strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Sort Order:</label>
                              <strong>{selectedLessonUnit.sort_order}</strong>
                            </div>

                            <div className="admin-info-half__description">
                              <label>Description:</label>
                              <div>
                                {selectedLessonUnit.description !== ""
                                  ? selectedLessonUnit.description
                                  : "Lorum ipsum lorum ipsum flo"}
                              </div>
                            </div>
                            <div className="admin-info-half__description">
                              <label>Text:</label>
                              <div>
                                {selectedLessonUnit.text !== ""
                                  ? selectedLessonUnit.text
                                  : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this unit and all of its contents?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "lesson/lesson_unit/delete",
                                      {
                                        lesson_unit_id:
                                          selectedLessonUnit.lesson_unit_id,
                                      }
                                    ).then((data) => {
                                      console.log(data);
                                      setEditingLessonUnit(false);
                                      setSelectedLessonUnit();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>

                        {lessonSubUnitList && (
                          <div className="admin-table-half">
                            <div className="admin-table-half__title">
                              Sub Units in Selected Unit
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  // setEditing(true);
                                  setCreating(true);
                                }}
                              >
                                <i className="material-icons">add</i>
                                <strong>New Sub Unit</strong>
                              </div>
                            </div>
                            <AdminTable
                              tableData={addSubUnitColumn(lessonSubUnitList)}
                              tableIds={lessonSubUnitListIds}
                              handleOrderChange={handleSubUnitOrderChange}
                              headers={
                                openSorting
                                  ? ["", "sort_order", "name"]
                                  : ["Unit", "name", "description"]
                              }
                              setSortChanging={setSortChanging}
                              idType={"lesson_unit_id"}
                              setSelectedItem={setSelectedSubUnit}
                              setEditingItem={setEditingSubUnit}
                            />
                            {openSorting ? (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "lesson/lesson_unit/reorderLessonUnits",
                                        {
                                          lesson_id: selectedLesson.lesson_id,
                                          parent_lesson_unit_id:
                                            selectedLessonUnit.lesson_unit_id,
                                          lesson_unit_ids: lessonSubUnitListIds,
                                        }
                                      ).then((data) => {
                                        HandyGolfAPI.sendRequest(
                                          "lesson/lesson_unit/list"
                                        ).then((data) => {
                                          getLessonSubUnits();
                                        });
                                      });
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Save Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(true);
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Set Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={
                            !creating
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCreating(false);
                                setUnitForm(initialUnitForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                New Sub Unit For the:<br></br>'
                                {selectedLessonUnit.name}' Unit
                              </h2>
                              <form
                                onSubmit={handleUnitCreateSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <label>Sub Unit Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder="name for a new unit"
                                    value={unitForm.name}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder="description of new unit"
                                    value={unitForm.description}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Sub Unit Text</label>
                                  <input
                                    type="text"
                                    name="text"
                                    placeholder="Text info of new unit"
                                    value={unitForm.text}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>Select Sub Unit Type</label>
                                  <div style={{ display: "flex", gap: "2rem" }}>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="normal lesson unit"
                                        value="normal lesson unit"
                                        checked={unitForm.unit_type === 1}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Normal Lesson Unit</label>
                                    </div>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="drill lesson unit"
                                        value="drill lesson unit"
                                        checked={unitForm.unit_type === 2}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Drill Lesson Unit</label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label>
                                    To edit unit sort order go back to previous
                                    page
                                  </label>
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setUnitForm(initialUnitForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit the Unit: <br></br> '
                                {selectedLessonUnit.name}'
                              </h2>
                              <form
                                onSubmit={handleUnitEditSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <label>Unit Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedLessonUnit.name}
                                    value={unitForm.name}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder={selectedLessonUnit.description}
                                    value={unitForm.description}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Unit Text</label>
                                  <input
                                    type="text"
                                    name="text"
                                    placeholder={selectedLessonUnit.text}
                                    value={unitForm.text}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>Select Unit Type</label>
                                  <div style={{ display: "flex", gap: "2rem" }}>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="normal lesson unit"
                                        value="normal lesson unit"
                                        checked={unitForm.unit_type === 1}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Normal Lesson Unit</label>
                                    </div>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="drill lesson unit"
                                        value="drill lesson unit"
                                        checked={unitForm.unit_type === 2}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Drill Lesson Unit</label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label>
                                    To edit unit sort order go back to previous
                                    page
                                  </label>
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      !editingSubUnit
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingSubUnit(false);
                          setSelectedSubUnit();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedSubUnit && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="admin-info-half"
                            style={{ maxWidth: "460px" }}
                          >
                            <div className="admin-info-half__title">
                              Sub Unit Details
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div>
                            </div>
                            <div className="admin-info-half__thumbnailbox">
                              <span>Sub Unit Thumbnail</span>
                              <div style={{ position: "relative" }}>
                                {!uploading && (
                                  <div
                                    className="admin-edit-thumbnail-button"
                                    onClick={() => {
                                      setUploading(true);
                                    }}
                                  >
                                    <i className="material-icons">edit</i>
                                    <strong>Edit Thumbnail</strong>
                                  </div>
                                )}

                                <img
                                  src={
                                    selectedSubUnit.thumbnail_url !== ""
                                      ? selectedSubUnit.thumbnail_url
                                      : "/img/thumbnail-absent.png"
                                  }
                                  style={
                                    uploading
                                      ? { opacity: ".5", transition: ".2s" }
                                      : { opacity: "1", transition: ".2s" }
                                  }
                                />
                                {uploading && (
                                  <>
                                    <div
                                      className="admin-edit-thumbnail-button"
                                      onClick={() => {
                                        setUploading(false);
                                      }}
                                    >
                                      <strong>Cancel</strong>
                                    </div>
                                    <Uploader
                                      folder={"lesson_unit_thumbnails/"}
                                      fileName={`lesson_unit_thumbnails/lesson_unit_${selectedSubUnit.lesson_unit_id}`}
                                      setUploading={setUploading}
                                      setNew={setSelectedSubUnit}
                                      id={selectedSubUnit.lesson_unit_id}
                                      type={"lesson_unit"}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="admin-info-half__infobox">
                              <div className="admin-info-half__key-value">
                                <label>Name:</label>
                                <strong>{selectedSubUnit.name}</strong>
                              </div>

                              <div className="admin-info-half__key-value">
                                <label>Unit Type:</label>
                                <strong>
                                  {selectedSubUnit.unit_type === 1
                                    ? "Normal Lesson Unit"
                                    : "Drill Lesson Unit"}
                                </strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Sort Order:</label>
                                <strong>{selectedSubUnit.sort_order}</strong>
                              </div>

                              <div className="admin-info-half__description">
                                <label>Description:</label>
                                <div>
                                  {selectedSubUnit.description !== ""
                                    ? selectedSubUnit.description
                                    : "Lorum ipsum lorum ipsum flo"}
                                </div>
                              </div>
                              <div className="admin-info-half__description">
                                <label>Text:</label>
                                <div>
                                  {selectedSubUnit.text !== ""
                                    ? selectedSubUnit.text
                                    : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "40px",
                                }}
                              >
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you SURE you want to PERMANENTLY DELETE this Sub Unit and all of its contents?"
                                      )
                                    ) {
                                      HandyGolfAPI.sendRequest(
                                        "lesson/lesson_unit/delete",
                                        {
                                          lesson_unit_id:
                                            selectedSubUnit.lesson_unit_id,
                                        }
                                      ).then((data) => {
                                        console.log(data);
                                        setEditingSubUnit(false);
                                        setSelectedSubUnit();
                                      });
                                    }
                                  }}
                                >
                                  <i className="material-icons">delete</i>
                                  <strong>Delete</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setUnitForm(initialUnitForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit the Sub Unit: <br></br> '
                                {selectedSubUnit.name}'
                              </h2>
                              <form
                                onSubmit={handleUnitEditSubmit}
                                className="admin-form"
                              >
                                <div>
                                  <label>Sub Unit Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedSubUnit.name}
                                    value={unitForm.name}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder={selectedSubUnit.description}
                                    value={unitForm.description}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Sub Unit Text</label>
                                  <input
                                    type="text"
                                    name="text"
                                    placeholder={selectedSubUnit.text}
                                    value={unitForm.text}
                                    onChange={handleUnitFormChange}
                                  />
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <label>Select Sub Unit Type</label>
                                  <div style={{ display: "flex", gap: "2rem" }}>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="normal lesson unit"
                                        value="normal lesson unit"
                                        checked={unitForm.unit_type === 1}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Normal Lesson Unit</label>
                                    </div>
                                    <div className="admin-radio-button">
                                      <input
                                        type="radio"
                                        name="unit_type"
                                        id="drill lesson unit"
                                        value="drill lesson unit"
                                        checked={unitForm.unit_type === 2}
                                        onChange={(e) => {
                                          const { name, value } = e.target;
                                          const numericValue =
                                            value === "normal lesson unit"
                                              ? 1
                                              : value === "drill lesson unit"
                                              ? 2
                                              : value;
                                          setUnitForm({
                                            ...unitForm,
                                            [name]: numericValue,
                                          });
                                        }}
                                      />
                                      <label>Drill Lesson Unit</label>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <label>
                                    To edit unit sort order go back to previous
                                    page
                                  </label>
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container__side-content--admin"></div> */}
      </div>
    </>
  );
}
