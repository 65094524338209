import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { AdminTable, Uploader } from "./index";
import { Button, AdminNav, Search } from "../components";
import { useUser } from "../providers";
import ReactPlayer from "react-player";

const initialFaultForm = {
  name: "",
  description: "",
  subtitle: "",
  has_children: 0,
  // sort_order:

  //   parent_trainer_category_id: 0,
};
const initialCauseForm = {
  name: "",
  description: "",
  subtitle: "",
  //fix_cause_category_id
  //fix_fault_id
};

const initialQuickFixForm = {};
export function AdminFaults() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  //cause create needs its own creating
  const [creatingCause, setCreatingCause] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  const [changingVideo, setChangingVideo] = useState();
  //faults
  const [parentList, setParentList] = useState();
  const [parentListIds, setParentListIds] = useState();
  const [children, setChildren] = useState(); //just the full list, use childList for everything
  const [childList, setChildList] = useState();
  const [childListIds, setChildListIds] = useState();
  const [selectedParent, setSelectedParent] = useState();
  const [editingParent, setEditingParent] = useState();
  const [selectedChild, setSelectedChild] = useState();
  const [editingChild, setEditingChild] = useState();
  //CAUSES
  const [causesList, setCausesList] = useState();
  const [selectedCause, setSelectedCause] = useState();
  const [editingCause, setEditingCause] = useState();
  //CAUSE CHANGING STATES
  const [changingCategory, setChangingCategory] = useState();
  const [changingLessons, setChangingLessons] = useState();
  const [changingTrainerVids, setChangingTrainerVids] = useState();
  const [changingPrograms, setChangingPrograms] = useState();
  const [hasChangedCategory, setHasChangedCategory] = useState();
  const [hasChangedLessons, setHasChangedLessons] = useState();
  const [hasChangedTrainerVids, setHasChangedTrainerVids] = useState();
  const [hasChangedPrograms, setHasChangedPrograms] = useState();

  //quickfixes
  const [quickFixes, setQuickFixes] = useState([]);
  const [creatingQuickFix, setCreatingQuickFix] = useState();
  //categories
  const [allCategories, setAllCategories] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [originalCategory, setOriginalCategory] = useState();
  //lessons
  const [lessonList, setLessonList] = useState();
  const [lessonParentUnits, setLessonParentUnits] = useState();
  const [lessonSubUnits, setLessonSubUnits] = useState();
  const [lessonUnitIdArray, setLessonUnitIdArray] = useState([]);
  const [origLessonUnitIdArray, setOrigLessonUnitIdArray] = useState([]);
  //trainer vids
  const [videoList, setVideoList] = useState();
  const [vidIdArray, setVidIdArray] = useState([]);
  const [originalVidIdArray, setOriginalVidIdArray] = useState([]);
  //programs
  const [allPrograms, setAllPrograms] = useState();
  const [programIdArray, setProgramIdArray] = useState([]);
  const [originalProgramIdArray, setOriginalProgramIdArray] = useState([]);
  //videoUrls
  const [parentVideoUrl, setParentVideoUrl] = useState();
  const [childVideoUrl, setChildVideoUrl] = useState();
  const [causeVideoUrl, setCauseVideoUrl] = useState();
  //video search fields
  //search fields
  const [searchString, setSearchString] = useState("");
  const [searchStringArray, setSearchStringArray] = useState([]);
  const [searchFilterVideos, setSearchFilterVideos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  //forms
  const [faultForm, setFaultForm] = useState(initialFaultForm);
  const [causeForm, setCauseForm] = useState(initialCauseForm);
  const [quickFixForm, setQuickFixForm] = useState(initialQuickFixForm);

  const getFaults = () => {
    HandyGolfAPI.sendRequest("fix/fault/list")
      .then((data) => {
        let parents = data.filter((item) => item.parent_fix_fault_id === 0);
        let children = data.filter((item) => item.parent_fix_fault_id !== 0);
        let parentIds = [];
        // let childIds = [];
        parents.forEach((el) => parentIds.push(el.fix_fault_id));
        // children.forEach((el) => childIds.push(el.fix_fault_id));
        setParentListIds(parentIds);
        // setChildListIds(childIds);
        setParentList(parents);
        setChildren(children);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const childListSetter = () => {
    let childFauts = children.filter(
      (fault) => fault.parent_fix_fault_id === selectedParent.fix_fault_id
    );
    let childIds = [];
    childFauts.forEach((child) => childIds.push(child.fix_fault_id));
    setChildListIds(childIds);
    setChildList(childFauts);
  };
  const getCauses = () => {
    HandyGolfAPI.sendRequest("fix/cause/list")
      .then((data) => {
        setCausesList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuickFixesParent = () => {
    HandyGolfAPI.sendRequest("fix/fault/getQuickfixVideoIds", {
      fix_fault_id: selectedParent.fix_fault_id,
    })
      .then((data) => {
        console.log("quickfixes parent", data);
        let vidIds = data.video_ids;
        vidIds.forEach((fixId) =>
          HandyGolfAPI.sendRequest("video/find", {
            video_id: fixId,
          }).then((data) => {
            if (data.video_url) {
              let tempData = {
                id: fixId,
                url: data.video_url,
                name: data.name,
              };
              setQuickFixes((prev) => [...prev, tempData]);
            } else {
              let tempData = { id: fixId, url: "" };
              setQuickFixes((prev) => [...prev, tempData]);
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuickFixesChild = () => {
    HandyGolfAPI.sendRequest("fix/fault/getQuickfixVideoIds", {
      fix_fault_id: selectedChild.fix_fault_id,
    })
      .then((data) => {
        let vidIds = data.video_ids;
        console.log("quickfixes child", data);
        vidIds.forEach((fixId) =>
          HandyGolfAPI.sendRequest("video/find", {
            video_id: fixId,
          }).then((data) => {
            if (data.video_url) {
              let tempData = {
                id: fixId,
                url: data.video_url,
                name: data.name,
              };
              setQuickFixes((prev) => [...prev, tempData]);
            } else {
              let tempData = { id: fixId, url: "" };
              setQuickFixes((prev) => [...prev, tempData]);
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuickFixUrls = (id) => {
    HandyGolfAPI.sendRequest("video/find", {
      video_id: id,
    }).then((data) => {
      return data.video_url;
    });
  };
  const getParentVideoUrl = () => {
    if (selectedParent.video_id !== 0) {
      HandyGolfAPI.sendRequest("video/find", {
        video_id: selectedParent.video_id,
      }).then((data) => {
        setParentVideoUrl(data.video_url);
      });
    }
  };
  const getChildVideoUrl = () => {
    if (selectedChild.video_id !== 0) {
      HandyGolfAPI.sendRequest("video/find", {
        video_id: selectedChild.video_id,
      }).then((data) => {
        setChildVideoUrl(data.video_url);
      });
    }
  };
  const getCauseVideoUrl = () => {
    if (selectedCause.video_id !== 0) {
      HandyGolfAPI.sendRequest("video/find", {
        video_id: selectedCause.video_id,
      }).then((data) => {
        setCauseVideoUrl(data.video_url);
      });
    }
  };
  const getAllCategories = () => {
    HandyGolfAPI.sendRequest("fix/category/list").then((data) => {
      setAllCategories(data);
    });
  };
  const getCurrentCategory = () => {
    if (selectedCause.fix_cause_category_id !== 0) {
      HandyGolfAPI.sendRequest("fix/category/find", {
        fix_cause_category_id: selectedCause.fix_cause_category_id,
      }).then((data) => {
        setOriginalCategory(data);
        setCurrentCategory(data);
      });
    }
  };
  const getAllLessons = () => {
    HandyGolfAPI.sendRequest("lesson/lesson/list").then((data) => {
      setLessonList(data);
    });
  };
  const getAllLessonUnits = () => {
    HandyGolfAPI.sendRequest("lesson/lesson_unit/list").then((data) => {
      let parentUnits = data.filter((unit) => unit.parent_lesson_unit_id === 0);
      let childUnits = data.filter((unit) => unit.parent_lesson_unit_id !== 0);
      setLessonParentUnits(parentUnits);
      setLessonSubUnits(childUnits);
    });
  };
  const filterSubUnits = (parentId) => {
    let tempSubs = lessonSubUnits;
    let filteredSubs = tempSubs.filter(
      (unit) => unit.parent_lesson_unit_id === parentId
    );
    return filteredSubs;
  };

  const getCurrentLessonUnits = () => {
    HandyGolfAPI.sendRequest("fix/cause/getLessonUnitIds", {
      fix_cause_id: selectedCause.fix_cause_id,
    }).then((data) => {
      setOrigLessonUnitIdArray(data.lesson_unit_ids);
      setLessonUnitIdArray(data.lesson_unit_ids);
    });
  };

  const getAllVideos = () => {
    HandyGolfAPI.sendRequest("trainer/video/list")
      .then((data) => {
        setVideoList(data);
        setSearchResults(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCurrentVideos = () => {
    HandyGolfAPI.sendRequest("fix/cause/getTrainerVideoIds", {
      fix_cause_id: selectedCause.fix_cause_id,
    }).then((data) => {
      setOriginalVidIdArray(data.trainer_video_ids);
      setVidIdArray(data.trainer_video_ids);
    });
  };
  const getAllPrograms = () => {
    HandyGolfAPI.sendRequest("trainer/program/list").then((data) => {
      setAllPrograms(data);
    });
  };
  const getCurrentPrograms = () => {
    HandyGolfAPI.sendRequest("fix/cause/getTrainerProgramIds", {
      fix_cause_id: selectedCause.fix_cause_id,
    }).then((data) => {
      setOriginalProgramIdArray(data.trainer_program_ids);
      setProgramIdArray(data.trainer_program_ids);
    });
  };
  //SEARCH FUNCTIONS:
  const searchStringCheck = (title, searchTerm) => {
    let titleSplit = title.split(" ");
    // let allSearchTerms = titleSplit.concat(catNames, equipNames);
    let allSearchTerms = titleSplit;
    for (let i = 0; i < allSearchTerms.length; i++) {
      if (allSearchTerms[i].includes(searchTerm)) {
        return true;
      }
    }
    return false;
  };
  const searchResultSetter = () => {
    let splitStringArray = searchString.split(" ");
    let properCapsStringArray = splitStringArray.map(
      (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    );

    if (properCapsStringArray.length > 0) {
      properCapsStringArray = properCapsStringArray.filter(
        (term) => term.length > 1
      );
      let mergedArray = [];

      properCapsStringArray.forEach((term) => {
        const singleSearchTermVideoArray = videoList.filter((video) =>
          searchStringCheck(
            video.name,

            term
          )
        );

        mergedArray = [...mergedArray, ...singleSearchTermVideoArray];
      });
      const uniqueArrayNoDuplicates = [...new Set(mergedArray)];

      setSearchResults(uniqueArrayNoDuplicates);
    }
  };
  let searchTimer = null;

  const handleSearchChange = (e) => {
    setSearchString(e.target.value);

    if (searchTimer == null) {
      searchTimer = setTimeout(() => {
        if (e.target.value === "") {
          setSearchResults(videoList);
        } else {
          searchResultSetter();
        }
      }, 300);
    }
  };
  const onSearchSubmit = (e) => {
    e.preventDefault();

    clearTimeout(searchTimer);
    if (searchString === "") {
      setSearchResults(videoList);
    } else {
      searchResultSetter();
    }
  };
  const onTrainerVidClick = (newId) => {
    if (!vidIdArray.includes(newId)) {
      setVidIdArray((prev) => [...prev, newId]);
    } else {
      let tempArr = vidIdArray;
      tempArr.filter((id) => id !== newId);

      setVidIdArray(tempArr);
    }
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    getAllLessons();
    getAllCategories();
    getAllLessonUnits();
    getAllVideos();
    getAllPrograms();
  }, []);
  useEffect(() => {
    if (!selectedParent) {
      getFaults();
      getCauses();
    } else {
      childListSetter();
      if (selectedParent.has_children === 0) {
        setQuickFixes([]);
        getQuickFixesParent();
      }
      if (selectedParent.video_id !== 0) {
        getParentVideoUrl();
      }
    }
  }, [selectedParent]);
  useEffect(() => {
    if (selectedChild) {
      setQuickFixes([]);
      getChildVideoUrl();
      getQuickFixesChild();
    }
  }, [selectedChild]);
  useEffect(() => {
    if (selectedCause) {
      getCauseVideoUrl();
      getCurrentCategory();
      getCurrentLessonUnits();
      getCurrentVideos();
      getCurrentPrograms();
    }
  }, [selectedCause]);
  const lessonIdToName = (id) => {
    let tempArr = lessonSubUnits;
    let convertedSub = tempArr.find((sub) => sub.lesson_unit_id === id);

    return convertedSub ? convertedSub.name : `No sub of id ${id}`;
  };
  const vidIdToName = (id) => {
    let tempArr = videoList;
    let convertedVid = tempArr.find((vid) => vid.trainer_video_id === id);

    return convertedVid ? convertedVid.name : `No vid of id ${id}`;
  };
  const programIdToName = (id) => {
    let tempArr = allPrograms;
    let convertedProgram = tempArr.find(
      (program) => program.trainer_program_id === id
    );

    return convertedProgram ? convertedProgram.name : `No vid of id ${id}`;
  };
  const filterCauses = (data) => {
    if (selectedChild) {
      let causes = data.filter(
        (cause) => cause.fix_fault_id === selectedChild.fix_fault_id
      );
      return causes;
    } else if (selectedParent && !selectedChild) {
      let causes = data.filter(
        (cause) => cause.fix_fault_id === selectedParent.fix_fault_id
      );
      return causes;
    }
  };
  const filterVids = (data) => {
    let filteredVids = data.filter((vid) =>
      vidIdArray.includes(vid.trainer_video_id)
    );
    return filteredVids;
  };
  const handleParentOrderChange = (direction, parentId) => {
    let tempIdsArray = parentListIds;
    let tempParents = parentList;
    let currentIndex = parentListIds.findIndex((id) => id === parentId);
    let idElement = parentListIds[currentIndex];
    let parentElement = parentList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempParents.splice(currentIndex, 1);
      tempParents.splice(currentIndex - 1, 0, parentElement);
      setParentList(tempParents);
      setParentListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempParents.splice(currentIndex, 1);
      tempParents.splice(currentIndex + 1, 0, parentElement);
      setParentList(tempParents);
      setParentListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };

  const handleChildOrderChange = (direction, childId) => {
    let tempIdsArray = childListIds;
    let tempChildList = childList;
    let currentIndex = childListIds.findIndex((id) => id === childId);
    let idElement = childListIds[currentIndex];
    let childElement = childList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempChildList.splice(currentIndex, 1);
      tempChildList.splice(currentIndex - 1, 0, childElement);
      setChildList(tempChildList);
      setChildListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempChildList.splice(currentIndex, 1);
      tempChildList.splice(currentIndex + 1, 0, childElement);
      setChildList(tempChildList);
      setChildListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleFaultFormChange = (e) => {
    setFaultForm({ ...faultForm, [e.target.name]: e.target.value });
  };

  const handleCauseFormChange = (e) => {
    setCauseForm({ ...causeForm, [e.target.name]: e.target.value });
  };
  const handleParentCreateSubmit = (e) => {
    e.preventDefault();
    let parentForm = {
      ...faultForm,
      hasChildren: 0,
      parent_fix_fault_id: 0,
    };

    HandyGolfAPI.sendRequest("fix/fault/create", {
      fix_fault: parentForm,
    })
      .then((data) => {
        let parents = parentList;
        let newParent = data.data;
        parents.push(newParent);
        setParentList(parents);
        setFaultForm(initialFaultForm);
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleParentEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("fix/fault/update", {
      fix_fault_id: selectedParent.fix_fault_id,
      fix_fault: faultForm,
    })
      .then((data) => {
        setSelectedParent(data.data);
        setFaultForm(initialFaultForm);
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChildCreateSubmit = (e) => {
    e.preventDefault();
    let childForm = {
      ...faultForm,
      hasChildren: 0,
      parent_fix_fault_id: selectedParent.fix_fault_id,
    };
    HandyGolfAPI.sendRequest("fix/fault/create", {
      fix_fault: childForm,
    })
      .then((data) => {
        let kids = children;
        let newKid = data.data;
        kids.push(newKid);
        setChildren(kids);
        setFaultForm(initialFaultForm);
        setCreating(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChildEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("fix/fault/create", {
      fix_fault_id: selectedChild.fix_fault_id,
      fix_fault: faultForm,
    })
      .then((data) => {
        setSelectedChild(data.data);
        setFaultForm(initialFaultForm);
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCauseCreateSubmit = (e) => {
    e.preventDefault();
    let causeObject = { ...causeForm };
    if (selectedChild) {
      causeObject = {
        ...causeObject,
        fix_fault_id: selectedChild.fix_fault_id,
      };
    } else {
      causeObject = {
        ...causeObject,
        fix_fault_id: selectedParent.fix_fault_id,
      };
    }
    HandyGolfAPI.sendRequest("fix/cause/create", {
      fix_cause: causeObject,
    })
      .then((data) => {
        let causes = causesList;
        let newCause = data.data;
        causes.push(newCause);
        setCausesList(causes);
        setCauseForm(initialCauseForm);
        setCreatingCause(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCauseEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("fix/cause/update", {
      fix_cause_id: selectedCause.fix_cause_id,
      fix_cause: causeForm,
    })
      .then((data) => {
        setSelectedCause(data.data);
        setCauseForm(initialCauseForm);
        setEditing(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //CAUSE SELECTOR CHANGES
  const handleLessonsChange = (e) => {
    let tempArray = [...lessonUnitIdArray];
    let newId = parseInt(e.target.value);
    const index = tempArray.indexOf(newId);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(newId);
    }
    setLessonUnitIdArray(tempArray);
    if (!hasChangedLessons) {
      setHasChangedLessons(true);
    }
  };
  const handleVidsChange = (e) => {
    let tempArray = [...vidIdArray];
    let newId = parseInt(e.target.value);
    const index = tempArray.indexOf(newId);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(newId);
    }
    setVidIdArray(tempArray);
    if (!hasChangedTrainerVids) {
      setHasChangedTrainerVids(true);
    }
  };
  const handleProgramsChange = (e) => {
    let tempArray = [...programIdArray];
    let newId = parseInt(e.target.value);
    const index = tempArray.indexOf(newId);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(newId);
    }
    setProgramIdArray(tempArray);
    if (!hasChangedPrograms) {
      setHasChangedPrograms(true);
    }
  };
  const videoTypeConverter = (type) => {
    if (type === 0) {
      return "No Type Selected";
    }
    if (type === 1) {
      return "Drill";
    }
    if (type === 2) {
      return "Guided Training";
    }

    if (type === "No Type Selected") {
      return 0;
    }
    if (type === "Drill") {
      return 1;
    }
    if (type === "Guided Training") {
      return 2;
    }
  };
  //TODO: every video editor & image needs an uploader, quickfixes adding /editing is a mystery, error updating category of fix_cause - sept 30 2024
  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              <div className="admin-action">
                <div className="admin-action-view">
                  {parentList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        Faults
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Fault</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={parentList}
                        tableIds={parentListIds}
                        handleOrderChange={handleParentOrderChange}
                        headers={
                          openSorting ? ["", "sort_order", "name"] : ["name"]
                        }
                        setSortChanging={setSortChanging}
                        idType={"fix_fault_id"}
                        setSelectedItem={setSelectedParent}
                        setEditingItem={setEditingParent}
                        // typeConverter={videoTypeConverter}
                      />
                      {openSorting ? (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(false);
                              }}
                            >
                              <strong>Cancel</strong>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                HandyGolfAPI.sendRequest(
                                  "fix/fault/reorderFaults",
                                  {
                                    parent_fix_fault_id: 0,
                                    fix_fault_ids: parentListIds,
                                  }
                                ).then((data) => {
                                  getFaults();
                                });
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Save Sort Order</strong>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                setOpenSorting(true);
                              }}
                            >
                              <i className="material-icons">swap_vert</i>
                              <strong>Set Sort Order</strong>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setFaultForm(initialFaultForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Fault</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleParentCreateSubmit}
                        >
                          <div>
                            <label>Fault Name</label>
                            <input
                              type="text"
                              name="name"
                              value={faultForm.name}
                              placeholder="fault name here"
                              onChange={handleFaultFormChange}
                            />
                          </div>
                          <div>
                            <label>Fault Subtitle</label>
                            <input
                              type="text"
                              name="subtitle"
                              value={faultForm.subtitle}
                              placeholder="fault subtitle here"
                              onChange={handleFaultFormChange}
                            />
                          </div>
                          <div>
                            <label>Fault Description</label>
                            <input
                              type="text"
                              name="description"
                              value={faultForm.description}
                              placeholder="fault description here"
                              onChange={handleFaultFormChange}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <label>Does this fault have sub faults?</label>
                            <div style={{ display: "flex", gap: "2rem" }}>
                              <div className="admin-radio-button">
                                <input
                                  type="radio"
                                  name="has_children"
                                  id="no"
                                  value="no"
                                  checked={faultForm.has_children === 0}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    const numericValue =
                                      value === "no"
                                        ? 0
                                        : value === "yes"
                                        ? 1
                                        : value;
                                    setFaultForm({
                                      ...faultForm,
                                      [name]: numericValue,
                                    });
                                  }}
                                />
                                <label>No</label>
                              </div>
                              <div className="admin-radio-button">
                                <input
                                  type="radio"
                                  name="has_children"
                                  id="yes"
                                  value="yes"
                                  checked={faultForm.has_children === 1}
                                  onChange={(e) => {
                                    const { name, value } = e.target;
                                    const numericValue =
                                      value === "no"
                                        ? 0
                                        : value === "yes"
                                        ? 1
                                        : value;
                                    setFaultForm({
                                      ...faultForm,
                                      [name]: numericValue,
                                    });
                                  }}
                                />
                                <label>Yes</label>
                              </div>
                            </div>
                          </div>
                          <Button fluid primary>
                            Submit Fault
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !editingParent
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingParent(false);
                          setSelectedParent();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedParent && (
                      <>
                        <div className="admin-info-half">
                          <div className="admin-info-half__title">
                            {selectedParent.name}
                          </div>
                          <div className="admin-info-half__thumbnailbox">
                            <div className="admin-info-half__section-title">
                              Video
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setChangingVideo(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div>
                            </div>
                            {selectedParent.video_id !== 0 && parentVideoUrl ? (
                              <div style={{ position: "relative" }}>
                                <div className="admin-player-wrapper">
                                  <ReactPlayer
                                    className="admin-react-player"
                                    width="100%"
                                    height="100%"
                                    // light={
                                    //   selectedParent.image_url !== ""
                                    //     ? selectedParent.image_url
                                    //     : "/img/thumbnail-absent.png"
                                    // }
                                    url={parentVideoUrl}
                                    controls={true}
                                  />
                                </div>
                              </div>
                            ) : (
                              <>There is no video file</>
                            )}
                          </div>
                          <div className="admin-info-half__thumbnailbox">
                            <div className="admin-info-half__section-title">
                              Image
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  setUploading(
                                    uploading === true ? false : true
                                  );
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>
                                  {uploading === true ? "Cancel" : "Edit"}
                                </strong>
                              </div>
                            </div>

                            <div style={{ position: "relative" }}>
                              <img
                                src={
                                  selectedParent.image_url !== ""
                                    ? selectedParent.image_url
                                    : "/img/thumbnail-absent.png"
                                }
                                style={
                                  uploading
                                    ? { opacity: ".5", transition: ".2s" }
                                    : { opacity: "1", transition: ".2s" }
                                }
                              />
                              {uploading && (
                                <>
                                  <Uploader
                                    folder={"fix/images/"}
                                    fileName={`fix/images/fix_fault_${selectedParent.fix_fault_id}`}
                                    setUploading={setUploading}
                                    setNew={setSelectedParent}
                                    id={selectedParent.fix_fault_id}
                                    type={"fix_fault_image"}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                          <div className="admin-info-half__infobox">
                            <div className="admin-info-half__section-title">
                              Details
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Name:</label>
                              <strong>{selectedParent.name}</strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Subtitle:</label>
                              <strong>{selectedParent.subtitle}</strong>
                            </div>
                            <div className="admin-info-half__description">
                              <label>Description:</label>
                              <div>{selectedParent.description}</div>
                            </div>
                          </div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you SURE you want to PERMANENTLY DELETE this FIX FAULT and all of its contents?"
                                  )
                                ) {
                                  HandyGolfAPI.sendRequest("fix/fault/delete", {
                                    fix_fault_id: selectedParent.fix_fault_id,
                                  }).then((data) => {
                                    setEditingParent(false);
                                    setSelectedParent();
                                  });
                                }
                              }}
                            >
                              <i className="material-icons">delete</i>
                              <strong>Delete</strong>
                            </div>
                          </div>
                        </div>
                        {selectedParent.has_children === 1 ? (
                          <>
                            {selectedParent.parent_fix_fault_id === 0 &&
                              childList && (
                                <>
                                  {childList.length > 0 ? (
                                    <div className="admin-table-half">
                                      <div className="admin-table-half__title">
                                        Child Faults
                                        <div
                                          className="admin-edit-item-button"
                                          onClick={() => {
                                            setCreating(true);
                                          }}
                                        >
                                          <i className="material-icons">add</i>
                                          <strong>New Fault</strong>
                                        </div>
                                      </div>
                                      <AdminTable
                                        // tableData={filterChildren(children)}
                                        tableData={childList}
                                        tableIds={childListIds}
                                        handleOrderChange={
                                          handleChildOrderChange
                                        }
                                        headers={
                                          openSorting
                                            ? ["", "sort_order", "name"]
                                            : ["name"]
                                        }
                                        setSortChanging={setSortChanging}
                                        idType={"fix_fault_id"}
                                        setSelectedItem={setSelectedChild}
                                        setEditingItem={setEditingChild}
                                      />
                                      {openSorting ? (
                                        <>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                              height: "40px",
                                            }}
                                          >
                                            <div
                                              className="admin-edit-item-button"
                                              onClick={() => {
                                                setOpenSorting(false);
                                              }}
                                            >
                                              <strong>Cancel</strong>
                                            </div>
                                          </div>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                              height: "40px",
                                            }}
                                          >
                                            <div
                                              className="admin-edit-item-button"
                                              onClick={() => {
                                                HandyGolfAPI.sendRequest(
                                                  "fix/fault/reorderFaults",
                                                  {
                                                    parent_fix_fault_id:
                                                      selectedParent.fix_fault_id,
                                                    fix_fault_ids: childListIds,
                                                  }
                                                ).then((data) => {
                                                  getFaults();
                                                  childListSetter();
                                                });
                                              }}
                                            >
                                              <i className="material-icons">
                                                swap_vert
                                              </i>
                                              <strong>Save Sort Order</strong>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div
                                            style={{
                                              position: "relative",
                                              width: "100%",
                                              height: "40px",
                                            }}
                                          >
                                            <div
                                              className="admin-edit-item-button"
                                              onClick={() => {
                                                setOpenSorting(true);
                                              }}
                                            >
                                              <i className="material-icons">
                                                swap_vert
                                              </i>
                                              <strong>Set Sort Order</strong>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="admin-info-half">
                                      <div className="admin-info-half__title">
                                        This fault has no children
                                      </div>
                                      <div className="admin-info-half__infobox">
                                        <div className="admin-info-half__section-title">
                                          <br></br>
                                          <div
                                            className="admin-edit-item-button"
                                            onClick={() => {
                                              setCreating(true);
                                            }}
                                          >
                                            <i className="material-icons">
                                              add
                                            </i>
                                            <strong>New Fault</strong>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                          </>
                        ) : (
                          <>
                            {causesList && quickFixes && (
                              <>
                                <div className="admin-table-half">
                                  {causesList.length > 0 ? (
                                    <>
                                      <div className="admin-table-half__title">
                                        Causes
                                        <div
                                          className="admin-edit-item-button"
                                          onClick={() => {
                                            setCreatingCause(true);
                                          }}
                                        >
                                          <i className="material-icons">add</i>
                                          <strong>New Cause</strong>
                                        </div>
                                      </div>
                                      <AdminTable
                                        tableData={filterCauses(causesList)}
                                        headers={["name"]}
                                        setSelectedItem={setSelectedCause}
                                        setEditingItem={setEditingCause}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div className="admin-info-half__title">
                                        This fault has no causes
                                      </div>
                                      <div className="admin-info-half__infobox">
                                        <div className="admin-info-half__section-title">
                                          <br></br>
                                          <div
                                            className="admin-edit-item-button"
                                            onClick={() => {
                                              setCreatingCause(true);
                                            }}
                                          >
                                            <i className="material-icons">
                                              add
                                            </i>
                                            <strong>New Cause</strong>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}

                                  <div className="admin-quick-fix">
                                    <div className="admin-quick-fix__title">
                                      Quick Fix Videos
                                      <div
                                        className="admin-edit-item-button"
                                        onClick={() => {
                                          setCreatingQuickFix(true);
                                        }}
                                      >
                                        <i className="material-icons">add</i>
                                        <strong>New Fix</strong>
                                      </div>
                                    </div>
                                    {quickFixes.length > 0 ? (
                                      <div className="admin-quick-fix-list">
                                        {quickFixes.map((fix, idx) => {
                                          return (
                                            <div key={idx}>
                                              <div className="admin-info-half__thumbnailbox">
                                                <div className="admin-info-half__section-title">
                                                  {fix.name}
                                                  <div
                                                    className="admin-edit-item-button"
                                                    onClick={() => {
                                                      setChangingVideo(true);
                                                    }}
                                                  >
                                                    <i className="material-icons">
                                                      edit
                                                    </i>
                                                    <strong>Edit</strong>
                                                  </div>
                                                </div>
                                                {fix.url !== "" ? (
                                                  <div
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    <div className="admin-player-wrapper">
                                                      <ReactPlayer
                                                        className="admin-react-player"
                                                        width="100%"
                                                        height="100%"
                                                        // light={
                                                        //   selectedVideo.thumbnail_url !== ""
                                                        //     ? selectedVideo.thumbnail_url
                                                        //     : "/img/thumbnail-absent.png"
                                                        // }
                                                        url={fix.url}
                                                        controls={true}
                                                      />
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <>There is no video file</>
                                                )}
                                                <div
                                                  style={{
                                                    position: "relative",
                                                    width: "100%",
                                                    height: "24px",
                                                  }}
                                                >
                                                  <div
                                                    className="admin-edit-item-button"
                                                    style={{
                                                      paddingBottom: "0",
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        window.confirm(
                                                          "Are you SURE you want to PERMANENTLY DELETE this QuickFix Video?"
                                                        )
                                                      ) {
                                                        HandyGolfAPI.sendRequest(
                                                          "fix/fault/removeQuickfixVideoId",
                                                          {
                                                            fix_fault_id:
                                                              selectedParent.fix_fault_id,
                                                            video_id:
                                                              fix.video_id,
                                                          }
                                                        ).then((data) => {
                                                          // console.log(data);
                                                          HandyGolfAPI.sendRequest(
                                                            "video/delete",
                                                            {
                                                              video_id:
                                                                fix.video_id,
                                                            }
                                                          ).then((data) => {
                                                            console.log(
                                                              "deleted quick fix",
                                                              data
                                                            );
                                                          });
                                                          // setEditingVideo(false);
                                                          // setSelectedVideo();
                                                        });
                                                      }
                                                    }}
                                                  >
                                                    <i className="material-icons">
                                                      delete
                                                    </i>
                                                    <strong>Delete</strong>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    ) : (
                                      <>nothing in quickfixes</>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}

                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setFaultForm(initialFaultForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit Fault: <br></br>'{selectedParent.name}'
                              </h2>
                              <form
                                className="admin-form"
                                onSubmit={handleParentEditSubmit}
                              >
                                <div>
                                  <label>Fault Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={faultForm.name}
                                    placeholder={selectedParent.name}
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Fault Subtitle</label>
                                  <input
                                    type="text"
                                    name="subtitle"
                                    value={faultForm.subtitle}
                                    placeholder={selectedParent.subtitle}
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Fault Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    value={faultForm.description}
                                    placeholder={selectedParent.description}
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Fault
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !creating
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCreating(false);
                                setFaultForm(initialFaultForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>Create New Fault</h2>
                              <form
                                className="admin-form"
                                onSubmit={handleChildCreateSubmit}
                              >
                                <div>
                                  <label>Fault Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={faultForm.name}
                                    placeholder="fault name here"
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Fault Subtitle</label>
                                  <input
                                    type="text"
                                    name="subtitle"
                                    value={faultForm.subtitle}
                                    placeholder="fault subtitle here"
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Fault Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    value={faultForm.description}
                                    placeholder="fault description here"
                                    onChange={handleFaultFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Fault
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                        {/* put creating and editing here */}
                        <div
                          className={
                            !editingChild
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditingChild(false);
                                setSelectedChild();
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          {selectedChild && (
                            <>
                              {/* <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              > */}
                              <div
                                className="admin-info-half"
                                style={{ maxWidth: "460px" }}
                              >
                                <div className="admin-info-half__title">
                                  {selectedChild.name}
                                </div>
                                <div className="admin-info-half__thumbnailbox">
                                  <div className="admin-info-half__section-title">
                                    Video
                                    <div
                                      className="admin-edit-item-button"
                                      onClick={() => {
                                        // setChangingVideo(true);
                                      }}
                                    >
                                      <i className="material-icons">edit</i>
                                      <strong>Edit</strong>
                                    </div>
                                  </div>
                                  {selectedChild.video_id !== 0 &&
                                  childVideoUrl ? (
                                    <div style={{ position: "relative" }}>
                                      <div className="admin-player-wrapper">
                                        <ReactPlayer
                                          className="admin-react-player"
                                          width="100%"
                                          height="100%"
                                          // light={
                                          //   selectedChild.image_url !== ""
                                          //     ? selectedChild.image_url
                                          //     : "/img/thumbnail-absent.png"
                                          // }
                                          url={childVideoUrl}
                                          controls={true}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>There is no video file</>
                                  )}
                                </div>
                                <div className="admin-info-half__thumbnailbox">
                                  <div className="admin-info-half__section-title">
                                    Image
                                    <div
                                      className="admin-edit-item-button"
                                      onClick={() => {
                                        setUploading(
                                          uploading === true ? false : true
                                        );
                                      }}
                                    >
                                      <i className="material-icons">edit</i>
                                      <strong>
                                        {uploading === true ? "Cancel" : "Edit"}
                                      </strong>
                                    </div>
                                  </div>

                                  <div style={{ position: "relative" }}>
                                    <img
                                      src={
                                        selectedChild.image_url !== ""
                                          ? selectedChild.image_url
                                          : "/img/thumbnail-absent.png"
                                      }
                                      style={
                                        uploading
                                          ? {
                                              opacity: ".5",
                                              transition: ".2s",
                                            }
                                          : {
                                              opacity: "1",
                                              transition: ".2s",
                                            }
                                      }
                                    />
                                    {uploading && (
                                      <>
                                        <Uploader
                                          folder={"fix/images/"}
                                          fileName={`fix/images/fix_fault_${selectedChild.fix_fault_id}`}
                                          setUploading={setUploading}
                                          setNew={setSelectedChild}
                                          id={selectedChild.fix_fault_id}
                                          type={"fix_fault_image"}
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                                <div className="admin-info-half__infobox">
                                  <div className="admin-info-half__section-title">
                                    Details
                                    <div
                                      className="admin-edit-item-button"
                                      onClick={() => {
                                        setEditing(true);
                                      }}
                                    >
                                      <i className="material-icons">edit</i>
                                      <strong>Edit</strong>
                                    </div>
                                  </div>
                                  <div className="admin-info-half__key-value">
                                    <label>Name:</label>
                                    <strong>{selectedChild.name}</strong>
                                  </div>
                                  <div className="admin-info-half__key-value">
                                    <label>Subtitle:</label>
                                    <strong>{selectedChild.subtitle}</strong>
                                  </div>
                                  <div className="admin-info-half__description">
                                    <label>Description:</label>
                                    <div>{selectedChild.description}</div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you SURE you want to PERMANENTLY DELETE this FIX FAULT and all of its contents?"
                                        )
                                      ) {
                                        HandyGolfAPI.sendRequest(
                                          "fix/fault/delete",
                                          {
                                            fix_fault_id:
                                              selectedChild.fix_fault_id,
                                          }
                                        ).then((data) => {
                                          setEditingChild(false);
                                          setSelectedChild();
                                        });
                                      }
                                    }}
                                  >
                                    <i className="material-icons">delete</i>
                                    <strong>Delete</strong>
                                  </div>
                                </div>
                              </div>
                              {causesList && (
                                <>
                                  <div className="admin-table-half">
                                    {causesList.length > 0 ? (
                                      <>
                                        <div className="admin-table-half__title">
                                          Causes
                                          <div
                                            className="admin-edit-item-button"
                                            onClick={() => {
                                              setCreatingCause(true);
                                            }}
                                          >
                                            <i className="material-icons">
                                              add
                                            </i>
                                            <strong>New Cause</strong>
                                          </div>
                                        </div>
                                        <AdminTable
                                          tableData={filterCauses(causesList)}
                                          headers={["name"]}
                                          setSelectedItem={setSelectedCause}
                                          setEditingItem={setEditingCause}
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <div className="admin-info-half__title">
                                          This fault has no causes
                                        </div>
                                        <div className="admin-info-half__infobox">
                                          <div className="admin-info-half__section-title">
                                            <br></br>
                                            <div
                                              className="admin-edit-item-button"
                                              onClick={() => {
                                                setCreatingCause(true);
                                              }}
                                            >
                                              <i className="material-icons">
                                                add
                                              </i>
                                              <strong>New Cause</strong>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                    <div className="admin-quick-fix">
                                      <div className="admin-quick-fix__title">
                                        Quick Fix Videos
                                        <div
                                          className="admin-edit-item-button"
                                          onClick={() => {
                                            setCreatingQuickFix(true);
                                          }}
                                        >
                                          <i className="material-icons">add</i>
                                          <strong>New Fix</strong>
                                        </div>
                                      </div>
                                      {quickFixes.length > 0 ? (
                                        <div className="admin-quick-fix-list">
                                          {quickFixes.map((fix, idx) => {
                                            return (
                                              <div key={idx}>
                                                <div className="admin-info-half__thumbnailbox">
                                                  <div className="admin-info-half__section-title">
                                                    {fix.name}
                                                    <div
                                                      className="admin-edit-item-button"
                                                      onClick={() => {
                                                        setChangingVideo(true);
                                                      }}
                                                    >
                                                      <i className="material-icons">
                                                        edit
                                                      </i>
                                                      <strong>Edit</strong>
                                                    </div>
                                                  </div>
                                                  {fix.url !== "" ? (
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <div className="admin-player-wrapper">
                                                        <ReactPlayer
                                                          className="admin-react-player"
                                                          width="100%"
                                                          height="100%"
                                                          // light={
                                                          //   selectedVideo.thumbnail_url !== ""
                                                          //     ? selectedVideo.thumbnail_url
                                                          //     : "/img/thumbnail-absent.png"
                                                          // }
                                                          url={fix.url}
                                                          controls={true}
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <>There is no video file</>
                                                  )}
                                                  <div
                                                    style={{
                                                      position: "relative",
                                                      width: "100%",
                                                      height: "24px",
                                                    }}
                                                  >
                                                    <div
                                                      className="admin-edit-item-button"
                                                      style={{
                                                        paddingBottom: "0",
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          window.confirm(
                                                            "Are you SURE you want to PERMANENTLY DELETE this QuickFix Video?"
                                                          )
                                                        ) {
                                                          HandyGolfAPI.sendRequest(
                                                            "fix/fault/removeQuickfixVideoId",
                                                            {
                                                              fix_fault_id:
                                                                selectedChild.fix_fault_id,
                                                              video_id:
                                                                fix.video_id,
                                                            }
                                                          ).then((data) => {
                                                            // console.log(data);
                                                            HandyGolfAPI.sendRequest(
                                                              "video/delete",
                                                              {
                                                                video_id:
                                                                  fix.video_id,
                                                              }
                                                            ).then((data) => {
                                                              console.log(
                                                                "deleted quick fix",
                                                                data
                                                              );
                                                            });
                                                            // setEditingVideo(false);
                                                            // setSelectedVideo();
                                                          });
                                                        }
                                                      }}
                                                    >
                                                      <i className="material-icons">
                                                        delete
                                                      </i>
                                                      <strong>Delete</strong>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <>nothing in quickfixes</>
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                              {/* </div> */}
                              <div
                                className={
                                  !editing
                                    ? "admin-action-edit-screen"
                                    : "admin-action-edit-screen admin-action-edit-screen--open"
                                }
                              >
                                <div className="admin-action-view__edit-button">
                                  <Button
                                    primary
                                    iconLeading="expand_more"
                                    onClick={() => {
                                      setEditing(false);
                                      setFaultForm(initialFaultForm);
                                    }}
                                  >
                                    Close
                                  </Button>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <h2>
                                      Edit Fault: <br></br>'{selectedChild.name}
                                      '
                                    </h2>
                                    <form
                                      className="admin-form"
                                      onSubmit={handleChildEditSubmit}
                                    >
                                      <div>
                                        <label>Fault Name</label>
                                        <input
                                          type="text"
                                          name="name"
                                          value={faultForm.name}
                                          placeholder={selectedChild.name}
                                          onChange={handleFaultFormChange}
                                        />
                                      </div>
                                      <div>
                                        <label>Fault Subtitle</label>
                                        <input
                                          type="text"
                                          name="subtitle"
                                          value={faultForm.subtitle}
                                          placeholder={selectedChild.subtitle}
                                          onChange={handleFaultFormChange}
                                        />
                                      </div>
                                      <div>
                                        <label>Fault Description</label>
                                        <input
                                          type="text"
                                          name="description"
                                          value={faultForm.description}
                                          placeholder={
                                            selectedChild.description
                                          }
                                          onChange={handleFaultFormChange}
                                        />
                                      </div>
                                      <Button fluid primary>
                                        Submit Fault
                                      </Button>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {/* trying editing  & cause here */}
                  <div
                    className={
                      !creatingCause
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreatingCause(false);
                          setCauseForm(initialCauseForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Cause</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleCauseCreateSubmit}
                        >
                          <div>
                            <label>Cause Name</label>
                            <input
                              type="text"
                              name="name"
                              value={causeForm.name}
                              placeholder="cause name here"
                              onChange={handleCauseFormChange}
                            />
                          </div>
                          <div>
                            <label>Cause Subtitle</label>
                            <input
                              type="text"
                              name="subtitle"
                              value={causeForm.subtitle}
                              placeholder="cause subtitle here"
                              onChange={handleCauseFormChange}
                            />
                          </div>
                          <div>
                            <label>Cause Description</label>
                            <input
                              type="text"
                              name="description"
                              value={causeForm.description}
                              placeholder="cause description here"
                              onChange={handleCauseFormChange}
                            />
                          </div>
                          <Button fluid primary>
                            Submit New Cause
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      !editingCause
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingCause(false);
                          setSelectedCause();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedCause && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="admin-info-half"
                            style={{ maxWidth: "460px" }}
                          >
                            <div className="admin-info-half__title">
                              {selectedCause.name}
                            </div>
                            <div className="admin-info-half__thumbnailbox">
                              <div className="admin-info-half__section-title">
                                Video
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    // setChangingVideo(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit</strong>
                                </div>
                              </div>
                              {selectedCause.video_id !== 0 && causeVideoUrl ? (
                                <div style={{ position: "relative" }}>
                                  <div className="admin-player-wrapper">
                                    <ReactPlayer
                                      className="admin-react-player"
                                      width="100%"
                                      height="100%"
                                      // light={
                                      //   selectedParent.image_url !== ""
                                      //     ? selectedParent.image_url
                                      //     : "/img/thumbnail-absent.png"
                                      // }
                                      url={causeVideoUrl}
                                      controls={true}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <>There is no video file</>
                              )}
                            </div>
                            <div className="admin-info-half__thumbnailbox">
                              <div className="admin-info-half__section-title">
                                Image
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setUploading(
                                      uploading === true ? false : true
                                    );
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>
                                    {uploading === true ? "Cancel" : "Edit"}
                                  </strong>
                                </div>
                              </div>

                              <div style={{ position: "relative" }}>
                                <img
                                  src={
                                    selectedCause.image_url !== ""
                                      ? selectedCause.image_url
                                      : "/img/thumbnail-absent.png"
                                  }
                                  style={
                                    uploading
                                      ? { opacity: ".5", transition: ".2s" }
                                      : { opacity: "1", transition: ".2s" }
                                  }
                                />
                                {uploading && (
                                  <>
                                    <Uploader
                                      folder={"fix/images/"}
                                      fileName={`fix/images/fix_cause_${selectedCause.fix_cause_id}`}
                                      setUploading={setUploading}
                                      setNew={setSelectedCause}
                                      id={selectedCause.fix_cause_id}
                                      type={"fix_cause_image"}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div
                              className="admin-info-half__infobox"
                              style={{ marginBottom: "1rem" }}
                            >
                              <div className="admin-info-half__section-title">
                                Details
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setEditing(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit</strong>
                                </div>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Name:</label>
                                <strong>{selectedCause.name}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Subtitle:</label>
                                <strong>{selectedCause.subtitle}</strong>
                              </div>
                              <div className="admin-info-half__description">
                                <label>Description:</label>
                                <div>{selectedCause.description}</div>
                              </div>
                            </div>
                            {currentCategory ? (
                              <div
                                className="admin-info-half__infobox"
                                style={{ marginBottom: "1rem" }}
                              >
                                <div className="admin-info-half__section-title">
                                  Category
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setChangingCategory(true);
                                    }}
                                  >
                                    <i className="material-icons">edit</i>
                                    <strong>Change Category</strong>
                                  </div>
                                </div>
                                <div className="admin-info-half__key-value">
                                  <strong>{currentCategory.name}</strong>
                                </div>
                              </div>
                            ) : (
                              <div
                                className="admin-info-half__infobox"
                                style={{ marginBottom: "1rem" }}
                              >
                                <div className="admin-info-half__section-title">
                                  Category
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setChangingCategory(true);
                                    }}
                                  >
                                    <i className="material-icons">edit</i>
                                    <strong>Change Category</strong>
                                  </div>
                                </div>
                                <div className="admin-info-half__key-value">
                                  <strong>No Category Selected</strong>
                                </div>
                              </div>
                            )}
                            <div
                              className="admin-info-half__infobox"
                              style={{ marginBottom: "1rem" }}
                            >
                              <div className="admin-info-half__section-title">
                                Lessons:
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setChangingLessons(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Change Lessons</strong>
                                </div>
                              </div>
                              {/* <div
                                style={{
                                  flexWrap: "wrap",
                                  display: "flex",
                                }}
                              > */}
                              <ul>
                                {lessonUnitIdArray.map((id) => (
                                  <li key={id}>{lessonIdToName(id)}</li>
                                ))}
                              </ul>

                              {/* </div> */}
                            </div>
                            <div
                              className="admin-info-half__infobox"
                              style={{ marginBottom: "1rem" }}
                            >
                              <div className="admin-info-half__section-title">
                                Trainer Videos:
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setChangingTrainerVids(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Change Videos</strong>
                                </div>
                              </div>
                              <ul>
                                {vidIdArray.map((id) => (
                                  <li key={id}>{vidIdToName(id)}</li>
                                ))}
                              </ul>
                            </div>
                            <div className="admin-info-half__infobox">
                              <div className="admin-info-half__section-title">
                                Programs:
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setChangingPrograms(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Change Programs</strong>
                                </div>
                              </div>
                              <ul>
                                {programIdArray.map((id) => (
                                  <li key={id}>{programIdToName(id)}, </li>
                                ))}
                              </ul>
                            </div>

                            {/* cause delete button */}
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this CAUSE and all of its contents?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "fix/cause/delete",
                                      {
                                        fix_cause_id:
                                          selectedCause.fix_cause_id,
                                      }
                                    ).then((data) => {
                                      setEditingCause(false);
                                      setSelectedCause();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setCauseForm(initialCauseForm);
                                // setHasChangedCategory(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>Editing Cause '{selectedCause.name}'</h2>
                              <form
                                className="admin-form"
                                onSubmit={handleCauseEditSubmit}
                              >
                                <div>
                                  <label>Cause Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    value={causeForm.name}
                                    placeholder={selectedCause.name}
                                    onChange={handleCauseFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Cause Subtitle</label>
                                  <input
                                    type="text"
                                    name="subtitle"
                                    value={causeForm.subtitle}
                                    placeholder={selectedCause.subtitle}
                                    onChange={handleCauseFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Cause Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    value={causeForm.description}
                                    placeholder={selectedCause.description}
                                    onChange={handleCauseFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Cause Edit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !changingCategory
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCurrentCategory(originalCategory);
                                setChangingCategory(false);
                                setHasChangedCategory(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          {allCategories && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div
                                className="admin-table-half"
                                style={{ maxWidth: "450px" }}
                              >
                                <h2>Select Category</h2>

                                <select
                                  name="category"
                                  onChange={(e) => {
                                    // setC;
                                    let newCat = allCategories.find(
                                      (cat) =>
                                        cat.fix_cause_category_id ===
                                        parseInt(e.target.value)
                                    );

                                    setCurrentCategory(newCat);
                                    if (!hasChangedCategory) {
                                      setHasChangedCategory(true);
                                    }
                                  }}
                                  style={{
                                    height: "50px",
                                    fontSize: "20px",
                                    width: "100%",
                                  }}
                                >
                                  {allCategories.map((cat, idx) => {
                                    return (
                                      <option
                                        key={idx}
                                        value={cat.fix_cause_category_id}
                                      >
                                        {cat.name}
                                      </option>
                                    );
                                  })}
                                </select>
                                <div style={{ marginTop: "1rem" }}>
                                  <Button
                                    fluid
                                    secondary
                                    onClick={() => {
                                      setCurrentCategory(originalCategory);
                                      setChangingCategory(false);
                                      setHasChangedCategory(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                                {hasChangedCategory && (
                                  <div style={{ marginTop: "1rem" }}>
                                    <Button
                                      fluid
                                      primary
                                      onClick={() => {
                                        let updatedCause = {
                                          fix_cause_category_id:
                                            currentCategory.fix_cause_category_id,
                                        };
                                        HandyGolfAPI.sendRequest(
                                          "fix/cause/update",
                                          {
                                            fix_cause_id:
                                              selectedCause.fix_cause_id,
                                            fix_cause: updatedCause,
                                          }
                                        ).then((data) => {
                                          setOriginalCategory(currentCategory);
                                          setChangingCategory(false);
                                          setHasChangedCategory(false);
                                        });
                                      }}
                                    >
                                      Submit Category
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            !changingLessons
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setLessonUnitIdArray(origLessonUnitIdArray);
                                setChangingLessons(false);
                                setHasChangedLessons(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              padding: "1rem",
                              // display: "flex",
                              // justifyContent: "center",
                            }}
                          >
                            <h2>Edit Lessons</h2>
                            <div
                              className="card-container"
                              // style={{
                              //   minWidth: "340px",
                              //   maxWidth: "460px",
                              // }}
                            >
                              {lessonList &&
                                lessonParentUnits &&
                                lessonSubUnits &&
                                lessonUnitIdArray && (
                                  <>
                                    {lessonList.map((lesson, idxA) => {
                                      return (
                                        <div
                                          key={idxA}
                                          className="admin-video-cats-wrapper"
                                          style={{
                                            maxHeight: "300px",
                                            overflowY: "scroll",
                                          }}
                                        >
                                          <div
                                            key={idxA}
                                            className="admin-video-cats-wrapper__title"
                                            style={{
                                              fontSize: "22px",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {lesson.name}
                                          </div>
                                          <div
                                          // className="admin-video-cats"
                                          // style={{ maxHeight: "400px" }}
                                          >
                                            {lessonParentUnits.map(
                                              (lessonParent, idxB) => {
                                                return lessonParent.lesson_id ===
                                                  lesson.lesson_id ? (
                                                  <div
                                                    key={idxB}
                                                    className="admin-video-cats__cat-boxes"
                                                  >
                                                    <div
                                                      key={idxB}
                                                      className="admin-video-cats__parent"
                                                      style={{
                                                        fontSize: "18px",
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      {lessonParent.name}
                                                    </div>
                                                    <div className="admin-video-cats__child">
                                                      {lessonSubUnits.map(
                                                        (subUnit, idxC) => {
                                                          return subUnit.parent_lesson_unit_id ===
                                                            lessonParent.lesson_unit_id ? (
                                                            <div
                                                              key={idxC}
                                                              className="admin-video-cats__child__child-box"
                                                              // style={{
                                                              //   display: "flex",
                                                              //   justifyContent:
                                                              //     "space-between",
                                                              // }}
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                paddingRight:
                                                                  "2rem",
                                                                // fontWeight: "bold",
                                                              }}
                                                            >
                                                              {subUnit.name}
                                                              <input
                                                                type="checkbox"
                                                                value={
                                                                  subUnit.lesson_unit_id
                                                                }
                                                                checked={lessonUnitIdArray.includes(
                                                                  subUnit.lesson_unit_id
                                                                )}
                                                                onChange={
                                                                  handleLessonsChange
                                                                }
                                                              />
                                                            </div>
                                                          ) : (
                                                            <></>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                            </div>
                            <div style={{ marginTop: "1rem" }}>
                              <Button
                                fluid
                                secondary
                                onClick={() => {
                                  setLessonUnitIdArray(origLessonUnitIdArray);
                                  setChangingLessons(false);
                                  setHasChangedLessons(false);
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            {hasChangedLessons && (
                              <div style={{ marginTop: "1rem" }}>
                                <Button
                                  fluid
                                  primary
                                  onClick={() => {
                                    HandyGolfAPI.sendRequest(
                                      "fix/cause/saveLessonUnits",
                                      {
                                        fix_cause_id:
                                          selectedCause.fix_cause_id,
                                        lesson_unit_ids: lessonUnitIdArray,
                                      }
                                    ).then((data) => {
                                      setOrigLessonUnitIdArray(
                                        lessonUnitIdArray
                                      );
                                      setChangingLessons(false);
                                      setHasChangedLessons(false);
                                    });
                                  }}
                                >
                                  Submit Lessons
                                </Button>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            !changingTrainerVids
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setVidIdArray(originalVidIdArray);
                                setChangingTrainerVids(false);
                                setHasChangedTrainerVids(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          {/* <div
                            style={{
                              width: "100%",
                              padding: "1rem",
                              display: "flex",
                              // justifyContent: "center",
                            }}
                          > */}
                          {searchResults && (
                            <div className="admin-table-half">
                              <h2>Search Videos</h2>
                              <Search
                                handleSearchChange={handleSearchChange}
                                onSearchSubmit={onSearchSubmit}
                              />
                              {/* <AdminTable
                                  tableData={searchResults}
                                  headers={["name", "type"]}
                                  setSelectedItem={onTrainerVidClick}
                                  setEditingItem={doNothing}
                                  typeConverter={videoTypeConverter}
                                /> */}
                              <div className="admin-table-wrapper">
                                <table className="admin-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Type</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {searchResults.map((item, rowIdx) => {
                                      return (
                                        <tr
                                          key={rowIdx}
                                          style={{ cursor: "default" }}
                                        >
                                          {["name", "type", ""].map(
                                            (header, itemIdx) => {
                                              return (
                                                <td key={itemIdx}>
                                                  {header === "" ? (
                                                    <input
                                                      style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                      type="checkbox"
                                                      value={
                                                        item.trainer_video_id
                                                      }
                                                      checked={vidIdArray.includes(
                                                        item.trainer_video_id
                                                      )}
                                                      onChange={
                                                        handleVidsChange
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                      onClick={() => {
                                                        onTrainerVidClick(
                                                          item.trainer_video_id
                                                        );

                                                        // setSelectedItem(item);
                                                        // setEditingItem(true);
                                                      }}
                                                      value={
                                                        item.trainer_video_id
                                                      }
                                                    >
                                                      {header === "type"
                                                        ? videoTypeConverter(
                                                            item[header]
                                                          )
                                                        : item[header]}
                                                    </div>
                                                  )}
                                                </td>
                                              );
                                            }
                                          )}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                          {searchResults && (
                            <div className="admin-table-half">
                              <h2>Currently Selected Videos</h2>
                              <div className="admin-table-wrapper">
                                <table className="admin-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Type</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filterVids(videoList).map(
                                      (item, rowIdx) => {
                                        return (
                                          <tr
                                            key={rowIdx}
                                            style={{ cursor: "default" }}
                                          >
                                            {["name", "type", ""].map(
                                              (header, itemIdx) => {
                                                return (
                                                  <td key={itemIdx}>
                                                    {header === "" ? (
                                                      <input
                                                        style={{
                                                          width: "40px",
                                                          height: "40px",
                                                          cursor: "pointer",
                                                        }}
                                                        type="checkbox"
                                                        value={
                                                          item.trainer_video_id
                                                        }
                                                        checked={vidIdArray.includes(
                                                          item.trainer_video_id
                                                        )}
                                                        onChange={
                                                          handleVidsChange
                                                        }
                                                      />
                                                    ) : (
                                                      <div
                                                        style={{
                                                          height: "100%",
                                                          display: "flex",
                                                          alignItems: "center",
                                                        }}
                                                        onClick={() => {
                                                          onTrainerVidClick(
                                                            item.trainer_video_id
                                                          );

                                                          // setSelectedItem(item);
                                                          // setEditingItem(true);
                                                        }}
                                                        value={
                                                          item.trainer_video_id
                                                        }
                                                      >
                                                        {header === "type"
                                                          ? videoTypeConverter(
                                                              item[header]
                                                            )
                                                          : item[header]}
                                                      </div>
                                                    )}
                                                  </td>
                                                );
                                              }
                                            )}
                                          </tr>
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <Button
                                  fluid
                                  secondary
                                  onClick={() => {
                                    setVidIdArray(originalVidIdArray);
                                    setChangingTrainerVids(false);
                                    setHasChangedTrainerVids(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                              {hasChangedTrainerVids && (
                                <div style={{ marginTop: "1rem" }}>
                                  <Button
                                    fluid
                                    primary
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "fix/cause/saveTrainerVideos",
                                        {
                                          fix_cause_id:
                                            selectedCause.fix_cause_id,
                                          trainer_video_ids: vidIdArray,
                                        }
                                      ).then((data) => {
                                        setOrigLessonUnitIdArray(vidIdArray);
                                        setChangingTrainerVids(false);
                                        setHasChangedTrainerVids(false);
                                      });
                                    }}
                                  >
                                    Submit Videos
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}

                          {/* </div> */}
                        </div>
                        <div
                          className={
                            !changingPrograms
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setVidIdArray(originalVidIdArray);
                                setChangingPrograms(false);
                                setHasChangedPrograms(false);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          {allPrograms && (
                            <div className="admin-table-half">
                              <h2>Select Programs</h2>
                              <div className="admin-table-wrapper">
                                <table className="admin-table">
                                  <thead>
                                    <tr>
                                      <th>Name</th>
                                      <th>Select Program</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {allPrograms.map((item, rowIdx) => {
                                      return (
                                        <tr
                                          key={rowIdx}
                                          style={{ cursor: "default" }}
                                        >
                                          {["name", ""].map(
                                            (header, itemIdx) => {
                                              return (
                                                <td key={itemIdx}>
                                                  {header === "" ? (
                                                    <input
                                                      style={{
                                                        width: "40px",
                                                        height: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                      type="checkbox"
                                                      value={
                                                        item.trainer_program_id
                                                      }
                                                      checked={programIdArray.includes(
                                                        item.trainer_program_id
                                                      )}
                                                      onChange={
                                                        handleProgramsChange
                                                      }
                                                    />
                                                  ) : (
                                                    <div
                                                      style={{
                                                        height: "100%",
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {item[header]}
                                                    </div>
                                                  )}
                                                </td>
                                              );
                                            }
                                          )}
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                              <div style={{ marginTop: "1rem" }}>
                                <Button
                                  fluid
                                  secondary
                                  onClick={() => {
                                    setProgramIdArray(originalProgramIdArray);
                                    setChangingPrograms(false);
                                    setHasChangedPrograms(false);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                              {hasChangedPrograms && (
                                <div style={{ marginTop: "1rem" }}>
                                  <Button
                                    fluid
                                    primary
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "fix/cause/saveTrainerPrograms",
                                        {
                                          fix_cause_id:
                                            selectedCause.fix_cause_id,
                                          trainer_program_ids: programIdArray,
                                        }
                                      ).then((data) => {
                                        setOrigLessonUnitIdArray(
                                          programIdArray
                                        );
                                        setChangingPrograms(false);
                                        setHasChangedPrograms(false);
                                      });
                                    }}
                                  >
                                    Submit Programs
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      !creatingQuickFix
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreatingQuickFix(false);
                          // setQuickFixForm(initialQuickFixForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>New Quick Fix</h2>
                        <h4>'does nothing atm'</h4>
                        <form className="admin-form">
                          <div>
                            <label>Quick Fix Name</label>
                            <input
                              type="text"
                              name="name"
                              value={quickFixForm.name}
                              placeholder="new video name"
                            />
                          </div>
                          <div>
                            <label>Quick Fix Subtitle</label>
                            <input
                              type="text"
                              name="subtitle"
                              value={quickFixForm.subtitle}
                              placeholder="new video subtitle"
                            />
                          </div>
                          <div>
                            <label>Quick Fix Description</label>
                            <input
                              type="text"
                              name="description"
                              value={quickFixForm.description}
                              placeholder="new video description"
                            />
                          </div>
                          <Button fluid primary>
                            Submit Quick Fix
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
