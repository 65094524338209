import React, { useState, useEffect } from "react";
import { FileUploadForm } from "./index";
import { Button } from "../../components";
import HandyGolf from "../../lib/HandyGolf";

import AWS from "aws-sdk";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
const defaultBucket = {
  name: HandyGolf.getValue("s3_image_bucket_name"),
  region: HandyGolf.getValue("s3_image_bucket_region"),
};
const defaultKeys = {
  accessKeyId: null,
  secretAccessKey: null,
};

export function Uploader({
  fileName,
  folder,
  setUploading,
  id,
  type,
  setNew,
  relatedObject,
  formFields,
}) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [keyCheckResult, setKeyCheckResult] = useState();
  const [keys, setKeys] = useState(defaultKeys);
  const [bucketInfo, setBucketInfo] = useState(defaultBucket);
  const [fileList, setFileList] = useState([]);

  const getCredentials = () => {
    HandyGolfAPI.sendRequest("user/getS3ImageBucketCreds")
      .then((data) => {
        //   console.log(data);
        //   setKeys({ accessKeyId: data.access, secretAccessKey: data.secret });
        //   setIsLoading(false)
        let accessKeyId = data.access;
        let secretAccessKey = data.secret;
        AWS.config.update({
          region: bucketInfo.region,
          credentials: { accessKeyId, secretAccessKey },
        });

        const s3 = new AWS.S3({
          params: { Bucket: bucketInfo.name },
          region: bucketInfo.region,
        });

        const params = { Bucket: bucketInfo.name };

        s3.listObjectsV2(params, (err, data) => {
          if (err) {
            console.error(err);
            setError(err.message || "Error fetching objects");
            setKeyCheckResult(false);
          } else {
            console.log(data.Contents);
            sessionStorage.setItem("accessKeyId", accessKeyId);
            sessionStorage.setItem("secretAccessKey", secretAccessKey);
            console.log("set keys in storage 2, 62");
            setFileList(data.Contents);
            setKeyCheckResult(true);
          }
          setIsLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    console.log("in useEffect 0, 74");
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    if (!accessKeyId || !secretAccessKey) {
      console.log("missing a key 1, 77");
      // setError("NO CREDENTIALS");
      //   setKeyCheckResult(false);
      getCredentials();
      //   setIsLoading(false);
      return;
    }
    setKeys({ accessKeyId, secretAccessKey });
    console.log("got keys from storage, set into state 3, 85");
    AWS.config.update({
      region: bucketInfo.region,
      credentials: { accessKeyId, secretAccessKey },
    });
    let s3Directory = HandyGolf.getValue("s3_image_folder") + "/" + folder;
    const params = { Bucket: bucketInfo.name, Prefix: s3Directory };
    console.log(params);
    const s3 = new AWS.S3({
      params: params,
      region: bucketInfo.region,
    });

    s3.listObjectsV2(params, (err, data) => {
      if (err) {
        console.error(err);
        setError(err.message || "Error fetching objects");
        setKeyCheckResult(false);
      } else {
        console.log(data.Contents);
        setFileList(data.Contents);
        setKeyCheckResult(true);
      }
      setIsLoading(false);
    });
  }, [bucketInfo]);

  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          padding: "1rem 1rem 1.5rem 1rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }
  console.log("past loading 4, 131");
  return (
    <>
      {keyCheckResult === true && (
        <>
          <FileUploadForm
            fileName={fileName}
            fileList={fileList}
            id={id}
            type={type}
            setUploading={setUploading}
            setNew={setNew}
            relatedObject={relatedObject}
            formFields={formFields}
          />
        </>
      )}
    </>
  );
}
