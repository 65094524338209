import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { AdminTable, Uploader } from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";

const initialCoachForm = {
  name: "",
  title: "",
  bio: "",
  facebook_url: "",
  instagram_url: "",
  youtube_url: "",
  website_url: "",
};
export function AdminCoaches() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  //see if we dont need these two V
  // const [loading, setLoading] = useState(true);
  // const [sortChanging, setSortChanging] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //coaches
  const [coachList, setCoachList] = useState();
  const [selectedCoach, setSelectedCoach] = useState();
  const [editingCoach, setEditingCoach] = useState();
  //forms
  const [coachForm, setCoachForm] = useState(initialCoachForm);

  const getCoaches = () => {
    HandyGolfAPI.sendRequest("coach/list")
      .then((data) => {
        console.log(data);
        setCoachList(data);
        //setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    if (!selectedCoach) {
      getCoaches();
    }
  }, [selectedCoach]);

  const handleCoachFormChange = (e) => {
    setCoachForm({
      ...coachForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCoachEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("coach/update", {
      coach_id: selectedCoach.coach_id,
      coach: coachForm,
    })
      .then((data) => {
        console.log(data);
        setSelectedCoach(data.data);
        setEditing(false);
        setCoachForm(initialCoachForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCoachCreateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("coach/create", {
      coach: coachForm,
    })
      .then((data) => {
        let newCoach = data.data;
        let coaches = coachList;
        coaches.push(newCoach);
        setCoachList(coaches);
        setCreating(false);
        setCoachForm(initialCoachForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              <div className="admin-action">
                <div className="admin-action-view">
                  {coachList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        HandyGolf Coaches
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Coach</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={coachList}
                        headers={["profile_picture_url", "name"]}
                        setSelectedItem={setSelectedCoach}
                        setEditingItem={setEditingCoach}
                      />
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setCoachForm(initialCoachForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Coach</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleCoachCreateSubmit}
                        >
                          <div>
                            <label>Coach Name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="name for new coach"
                              value={coachForm.name}
                              onChange={handleCoachFormChange}
                            />
                          </div>
                          <div>
                            <strong>
                              You can add profile picture on the coach edit
                              screen after the coach has been created
                            </strong>
                          </div>
                          <Button fluid primary>
                            Submit Coach
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* COACH PAGE */}
                  <div
                    className={
                      !editingCoach
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingCoach(false);
                          setSelectedCoach();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedCoach && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="admin-info-half"
                            style={{ maxWidth: "460px" }}
                          >
                            <div className="admin-info-half__title">
                              Coach Details
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div>
                            </div>
                            <div className="admin-info-half__thumbnailbox">
                              <span>Coach Picture</span>
                              <div style={{ position: "relative" }}>
                                {!uploading && (
                                  <div
                                    className="admin-edit-thumbnail-button"
                                    onClick={() => {
                                      setUploading(true);
                                    }}
                                  >
                                    <i className="material-icons">edit</i>
                                    <strong>Edit Picture</strong>
                                  </div>
                                )}

                                <img
                                  src={
                                    selectedCoach.profile_picture_url !== ""
                                      ? selectedCoach.profile_picture_url
                                      : "/img/no-profile-pic.png"
                                  }
                                  style={
                                    uploading
                                      ? { opacity: ".5", transition: ".2s" }
                                      : { opacity: "1", transition: ".2s" }
                                  }
                                />
                                {uploading && (
                                  <>
                                    <div
                                      className="admin-edit-thumbnail-button"
                                      onClick={() => {
                                        setUploading(false);
                                      }}
                                    >
                                      <strong>Cancel</strong>
                                    </div>
                                    <Uploader
                                      folder={"coaches/"}
                                      fileName={`coaches/coach_${selectedCoach.coach_id}`}
                                      setUploading={setUploading}
                                      setNew={setSelectedCoach}
                                      id={selectedCoach.coach_id}
                                      type={"coach"}
                                    />
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="admin-info-half__infobox">
                              <div className="admin-info-half__section-title">
                                Details
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    setEditing(true);
                                  }}
                                >
                                  <i className="material-icons">edit</i>
                                  <strong>Edit</strong>
                                </div>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Name:</label>
                                <strong>{selectedCoach.name}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Title:</label>
                                <strong>{selectedCoach.title}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Facebook:</label>
                                <strong>{selectedCoach.facebook_url}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Instagram:</label>
                                <strong>{selectedCoach.instagram_url}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>YouTube:</label>
                                <strong>{selectedCoach.youtube_url}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Website:</label>
                                <strong>{selectedCoach.website_url}</strong>
                              </div>
                              <div className="admin-info-half__description">
                                <label>Bio:</label>
                                <div>{selectedCoach.description}</div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "40px",
                                }}
                              >
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you SURE you want to PERMANENTLY DELETE this Coach?"
                                      )
                                    ) {
                                      HandyGolfAPI.sendRequest("coach/delete", {
                                        coach_id: selectedCoach.coach_id,
                                      }).then((data) => {
                                        console.log(data);
                                        setEditingCoach(false);
                                        setSelectedCoach();
                                      });
                                    }
                                  }}
                                >
                                  <i className="material-icons">delete</i>
                                  <strong>Delete</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setCoachForm(initialCoachForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit coach: <br></br>'{selectedCoach.name}'
                              </h2>
                              <form
                                className="admin-form"
                                onSubmit={handleCoachEditSubmit}
                              >
                                <div>
                                  <label>Change Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedCoach.name}
                                    value={coachForm.name}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change Title</label>
                                  <input
                                    type="text"
                                    name="title"
                                    placeholder={selectedCoach.title}
                                    value={coachForm.title}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change Facebook</label>
                                  <input
                                    type="text"
                                    name="facebook_url"
                                    placeholder={selectedCoach.facebook_url}
                                    value={coachForm.facebook_url}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change Instagram</label>
                                  <input
                                    type="text"
                                    name="instagram_url"
                                    placeholder={selectedCoach.instagram_url}
                                    value={coachForm.instagram_url}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change YouTube</label>
                                  <input
                                    type="text"
                                    name="youtube_url"
                                    placeholder={selectedCoach.youtube_url}
                                    value={coachForm.youtube_url}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change Website</label>
                                  <input
                                    type="text"
                                    name="website_url"
                                    placeholder={selectedCoach.website_url}
                                    value={coachForm.website_url}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Change Bio</label>
                                  <textarea
                                    rows="4"
                                    name="bio"
                                    placeholder={selectedCoach.bio}
                                    value={coachForm.bio}
                                    onChange={handleCoachFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Changes
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
