import React, { useState } from "react";
import AWS from "aws-sdk";

// Libraries
import HandyGolfAPI from "../lib/HandyGolfAPI";

// Components
import PageMetaData from "../components/PageMetaData";
import { useToast } from "../providers";
import { Feedback } from "../components";

// MUI
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import DeleteIcon from "@mui/icons-material/Delete";
import Slider from "@mui/material/Slider";

// Router
import { Navigate } from "react-router-dom";
import { S3Uploader } from "../components/s3_uploader";
import { Button as Bbutton } from "../components";

function DevDebuggerPage() {
  let line_type = "";
  let draw_canvas;
  let final_canvas;
  function clear_final_ctx() {}
  function get_canvas_objs() {
    draw_canvas = document.getElementById("drawCanvas");
    final_canvas = document.getElementById("finalCanvas");
  }
  React.useEffect(() => {
    get_canvas_objs();
    const draw_ctx = draw_canvas.getContext("2d");
    draw_ctx.strokeStyle = "#3ab549ff";
    draw_ctx.lineWidth = 6;
    draw_ctx.shadowColor = "#ffffffff";
    draw_ctx.shadowBlur = 10;
    const final_ctx = final_canvas.getContext("2d");
    final_ctx.strokeStyle = "#3ab549ff";
    final_ctx.lineWidth = 6;
    final_ctx.shadowColor = "#ffffffff";
    final_ctx.shadowBlur = 10;
    let start_x;
    let start_y;
    let current_x;
    let current_y;
    let interval;
    final_canvas.removeEventListener("mousedown", (e) => {
      start_x = e.offsetX;
      start_y = e.offsetY;
      current_x = e.offsetX;
      current_y = e.offsetY;
      clearInterval(interval);
      interval = setInterval(drawline, 50);
    });
    final_canvas.addEventListener("mousedown", (e) => {
      start_x = e.offsetX;
      start_y = e.offsetY;
      current_x = e.offsetX;
      current_y = e.offsetY;
      clearInterval(interval);
      interval = setInterval(drawline, 50);
    });
    final_canvas.removeEventListener("mousemove", (e) => {
      current_x = e.offsetX;
      current_y = e.offsetY;
    });
    final_canvas.addEventListener("mousemove", (e) => {
      current_x = e.offsetX;
      current_y = e.offsetY;
    });
    final_canvas.removeEventListener("mouseup", (e) => {
      clearInterval(interval);
      current_x = e.offsetX;
      current_y = e.offsetY;
      drawline(true);
    });
    final_canvas.addEventListener("mouseup", (e) => {
      clearInterval(interval);
      current_x = e.offsetX;
      current_y = e.offsetY;
      drawline(true);
    });

    function drawline(final = false) {
      console.log("Start: " + line_type + "...");
      draw_ctx.clearRect(0, 0, draw_canvas.width, draw_canvas.height);
      let this_ctx = draw_ctx;
      if (final) {
        this_ctx = final_ctx;
      }
      this_ctx.beginPath();

      if (line_type === "line") {
        this_ctx.moveTo(start_x, start_y);
        this_ctx.lineTo(current_x, current_y);
      } else if (line_type === "circle") {
        let radius = Math.sqrt(
          Math.pow(start_x - current_x, 2) + Math.pow(start_y - current_y, 2)
        );
        if (radius < 0) {
          radius *= -1;
        }
        this_ctx.arc(start_x, start_y, radius, 0, 2 * Math.PI);
        this_ctx.stroke();
      }
      console.log("... " + line_type + ": done");

      this_ctx.stroke();
    }

    clear_final_ctx = () => {
      console.log("test2");
      final_ctx.clearRect(0, 0, final_canvas.width, final_canvas.height);
    };

    // console.log('Debugger function...');
    // HandyGolfAPI.debugAPI()
    //     .then((response) => response.json() )
    //     .then(data => {
    //         console.log('Debug Response:');
    //         console.log(data);
    //     });
  }, []);
  const {
    error: errorToast,
    success: successToast,
    warning: warningToast,
    info: infoToast,
  } = useToast();
  return (
    <>
      {process.env.REACT_APP_ENV === "dev" ||
      process.env.NODE_ENV === "development" ||
      process.env.AMPLIFY_ENV === "dev" ? (
        <PageMetaData
          title="Development Debugger"
          seo="keywords for seo stuff"
          // DO NOT INDEX
        >
          <div className="body_content">
            <Typography variant="h3" gutterBottom>
              Debugger Page
            </Typography>
            <pre>{JSON.stringify(process.env, null, "\t")}</pre>
            <Bbutton primary iconLeading="lock">
              hddfadfasdfai
            </Bbutton>
            <Bbutton primary iconLeading="search">
              search
            </Bbutton>
            <Bbutton secondary iconTrailing="search">
              search
            </Bbutton>
            <Bbutton secondary>search</Bbutton>
            <Bbutton secondary iconLeading="edit">
              Edit
            </Bbutton>
            <Bbutton
              secondary
              fluid
              onClick={() => {
                HandyGolfAPI.sendRequest("user/checkToken").then((data) => {
                  console.log(data);
                });
              }}
            >
              checkuser
            </Bbutton>
            <Bbutton secondary fluid iconTrailing="search">
              hddfadfasdfai
            </Bbutton>
            <Bbutton primary disabled>
              hddfadfasdfai
            </Bbutton>
            <Bbutton secondary disabled iconLeading="lock">
              hddfadfasdfai
            </Bbutton>
            <S3Uploader />
            <Button variant="contained" disabled>
              butieoaei
            </Button>

            <br />
            <Button
              primary
              onClick={() => {
                HandyGolfAPI.sendRequest("user/getS3ImageBucketCreds").then(
                  (data) => {
                    console.log(data);
                  }
                );
              }}
            >
              Verify
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("lesson/lesson/getLessonList")
                  .then((data) => {
                    console.log("NEW_SUCCESS:");
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              get Lesson List
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("lesson/lesson/getLessonData", {
                  lesson_id: "30",
                })
                  .then((data) => {
                    // console.log("NEW_SUCCESS:");
                    console.log(data.lesson_data[0]);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              new lesson
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("lesson/lesson/getAllUserData", {
                  lesson_id: "30",
                  // lesson_unit_id: "39",
                })
                  .then((data) => {
                    // console.log("NEW_SUCCESS:");
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              get all user data
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("fix/fault/list", {
                  // video_id: 3,
                  // lesson_unit_id: "39",
                })
                  .then((data) => {
                    // console.log("NEW_SUCCESS:");
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              fixes
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("fix/cause/list", {
                  // video_id: 3,
                  // lesson_unit_id: "39",
                })
                  .then((data) => {
                    // console.log("NEW_SUCCESS:");
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              cause
            </Button>
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("lesson/getLessonUnitDetailsForUser", {
                  lesson_id: "28",
                })
                  .then((data) => {
                    console.log("NEW_SUCCESS:");
                    console.log(data);
                  })
                  .catch((err) => {
                    console.log(err.message);
                  });
              }}
            >
              LESSON
            </Button>

            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("trainer/getCategoryDetails", {
                  category_id: 1,
                }).then((data) => {
                  console.log("SUCCESS:");
                  console.log(data);
                });
              }}
            >
              Get Category Details
            </Button>
            <br />
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("trainer/addCategory", {
                  name: "Test",
                  show_on_dashboard: 1,
                }).then((data) => {
                  console.log("SUCCESS:");
                  console.log(data);
                });
              }}
            >
              Add Category
            </Button>
            <br />
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("trainer/editCategory", {
                  category_id: 1,
                  name: "Test",
                  show_on_dashboard: 1,
                }).then((data) => {
                  console.log("SUCCESS:");
                  console.log(data);
                });
              }}
            >
              Edit Category
            </Button>
            <br />
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("trainer/deleteCategory", {
                  category_id: 1,
                }).then((data) => {
                  console.log("SUCCESS:");
                  console.log(data);
                });
              }}
            >
              Delete Category
            </Button>
            <br />
            <Button
              onClick={() => {
                HandyGolfAPI.sendRequest("trainer/getUserActivity").then(
                  (data) => {
                    console.log("SUCCESS:");
                    console.log(data);
                  }
                );
              }}
            >
              get getUserActivity
            </Button>
            <br />
            <Button
              onClick={() => {
                errorToast("test error ");
              }}
            >
              error toast test
            </Button>
            <Button
              onClick={() => {
                successToast("success!");
              }}
            >
              success toast test
            </Button>
            <Button
              onClick={() => {
                warningToast("Ye be warned");
              }}
            >
              warning toast test
            </Button>
            <Button
              onClick={() => {
                infoToast("information here");
              }}
            >
              info toast test
            </Button>

            {/* <MUIButton type="primary">click me</MUIButton>
      <MUIButton type="secondary">click me</MUIButton>
      <MUIButton type="disabled">dont click</MUIButton> */}
            <div style={{ textAlign: "center" }}>
              <IconButton
                aria-label="line"
                onClick={() => (line_type = "line")}
              >
                <ShowChartIcon />
              </IconButton>
              <IconButton
                aria-label="circle"
                onClick={() => (line_type = "circle")}
              >
                <PanoramaFishEyeIcon />
              </IconButton>
              <IconButton aria-label="empty" onClick={() => clear_final_ctx()}>
                <DeleteIcon />
              </IconButton>
            </div>
            <br />
            <div
              style={{
                position: "relative",
                width: 1280,
                height: 720,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <img
                src="/img/scott1.jpg"
                id="analyzerimg"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 5,
                  width: 1280,
                  height: 720,
                }}
                alt="temp"
              />
              <canvas
                id="drawCanvas"
                style="border:1px solid grey"
                width="1280"
                height="720"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 10,
                  width: 1280,
                  height: 720,
                }}
              ></canvas>
              <canvas
                id="finalCanvas"
                style="border:1px solid grey"
                width="1280"
                height="720"
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  zIndex: 10,
                  width: 1280,
                  height: 720,
                }}
              ></canvas>
            </div>
            <div
              style={{
                width: 450,
                marginLeft: "auto",
                marginRight: "auto",
                textAlign: "center",
              }}
            >
              <Slider
                aria-label="Custom marks"
                defaultValue={1}
                step={1}
                valueLabelDisplay="auto"
                marks
                min={1}
                max={12}
                sx={{ zIndex: 1000 }}
                onChange={(event) =>
                  (document.getElementById("analyzerimg").src =
                    "/img/scott" + event.target.value + ".jpg")
                }
              />
            </div>
            <div>
              <Feedback />
            </div>
          </div>
        </PageMetaData>
      ) : (
        <>
          <Navigate to="/" />
          <pre>{JSON.stringify(process.env, null, "\t")}</pre>
        </>
      )}
    </>
  );
}

export default DevDebuggerPage;
