import React, { useEffect, useState } from "react";
import AWS from "aws-sdk";
import HandyGolf from "../../lib/HandyGolf";
import HandyGolfAPI from "../../lib/HandyGolfAPI";
import { DragDrop } from "./index";
import { Button } from "../../components";

const defaultBucket = {
  name: HandyGolf.getValue("s3_image_bucket_name"),
  region: HandyGolf.getValue("s3_image_bucket_region"),
};
export function FileUploadForm({
  fileName,
  fileList,
  id,
  type,
  setUploading,
  setNew,
  relatedObject,
  formFields,
}) {
  const [file, setFile] = useState(null);
  const [filePath, setFilePath] = useState();
  const [bucketInfo, setBucketInfo] = useState(defaultBucket);
  const [duration, setDuration] = useState();
  const uploadFile = async () => {
    const accessKeyId = sessionStorage.getItem("accessKeyId");
    const secretAccessKey = sessionStorage.getItem("secretAccessKey");
    if (!accessKeyId || !secretAccessKey) {
      // setError("NO CREDENTIALS");
      // setKeyCheckResult(false);
      console.log("hey there are no keys and nothing is being done about it");
      // setIsLoading(false);
      // return;
    }
    AWS.config.update({
      region: bucketInfo.region,
      credentials: { accessKeyId, secretAccessKey },
    });
    const s3 = new AWS.S3({
      params: { Bucket: bucketInfo.name },
      region: bucketInfo.region,
    });
    const params = {
      Bucket: bucketInfo.name,
      Key: filePath,
      Body: file,
    };
    console.log(params);
    let upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(
          "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        );
      })
      .promise();
    await upload.then((err, data) => {
      // console.log(err);
      // console.log(data);
      if (type === "lesson") {
        HandyGolfAPI.sendRequest("lesson/lesson/update", {
          lesson_id: id,
          lesson: {
            thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          console.log(data);
          setUploading(false);
          setNew(data.data);
        });
      }
      if (type === "lesson_unit") {
        HandyGolfAPI.sendRequest("lesson/lesson_unit/update", {
          lesson_unit_id: id,
          lesson_unit: {
            thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          console.log(data);
          setUploading(false);
          setNew(data.data);
        });
      }
      if (type === "trainer_thumbnail") {
        HandyGolfAPI.sendRequest("trainer/video/update", {
          trainer_video_id: id,
          trainer_video: {
            thumbnail_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          console.log(data);
          setUploading(false);
          setNew(data.data);
        });
      }
      if (type === "trainer_video") {
        HandyGolfAPI.sendRequest("video/create", {
          video: {
            name: `trainer_video_${id}`,
            description: relatedObject.description,
            thumbnail_url: relatedObject.thumbnail_url,
            video_length: duration,
            video_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          HandyGolfAPI.sendRequest("trainer/video/update", {
            trainer_video_id: id,
            trainer_video: { video_id: data.data.video_id },
          }).then((dataDos) => {
            console.log(dataDos);
            setUploading(false);
            setNew(dataDos.data);
          });
        });
      }
      if (type === "create_trainer_practice_video") {
        HandyGolfAPI.sendRequest("video/create", {
          video: {
            name: `${formFields.view}_practice_${relatedObject.name}`,
            video_length: duration,
            video_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          console.log(data);
          HandyGolfAPI.sendRequest("trainer/video/createPracticeVideo", {
            practice_video: {
              trainer_video_id: relatedObject.trainer_video_id,
              video_id: data.data.video_id,
              view: formFields.view,
            },
          }).then((dataDos) => {
            console.log(dataDos);
            setUploading(false);
            // setNew(dataDos.data);
          });
        });
      }
      if (type === "coach") {
        HandyGolfAPI.sendRequest("coach/update", {
          coach_id: id,
          coach: {
            profile_picture_url: HandyGolf.getValue("s3_image_url") + filePath,
          },
        }).then((data) => {
          console.log(data);
          setUploading(false);
          setNew(data.data);
        });
      }
    });
  };
  const handleFileChange = (file) => {
    if (file.type.includes("video")) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = () => {
        setDuration(Math.round(video.duration));
      };
    }

    let fileExtension = file.name.split(".")[file.name.split(".").length - 1];
    let fullFileName = HandyGolf.getValue("s3_image_folder") + "/" + fileName;
    let nameAndExt = fullFileName + "." + fileExtension;

    setFilePath(nameAndExt);
    setFile(file);
  };
  return (
    <>
      <DragDrop handleFileChange={handleFileChange} />
      {/* </div> */}
      {file !== null && (
        <div
          className="admin-file-upload-button"
          onClick={() => {
            uploadFile();
          }}
        >
          <i className="material-icons">upload</i>
          <strong>Upload File</strong>
        </div>
      )}
    </>
  );
}
