import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { ActivityPicker, AdminTable, Uploader } from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";

const initialProgramForm = {
  name: "",
  subtitle: "",
  description: "",
  recommended_frequency: "",
  session_length_in_minutes: 10,
};
export function AdminPrograms() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  //see if we dont need these two V
  // const [loading, setLoading] = useState(true);
  // const [sortChanging, setSortChanging] = useState();
  const [sortChanging, setSortChanging] = useState();
  const [openSorting, setOpenSorting] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //programs
  const [programList, setProgramList] = useState();
  const [programListIds, setProgramListIds] = useState();
  const [selectedProgram, setSelectedProgram] = useState();
  const [editingProgram, setEditingProgram] = useState();
  //sessions
  const [sessionList, setSessionList] = useState();
  const [sessionListIds, setSessionListIds] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [editingSession, setEditingSession] = useState();
  //activities
  const [activityList, setActivityList] = useState();
  const [activityListIds, setActivityListIds] = useState();
  const [selectedActivity, setSelectedActivity] = useState();
  const [editingActivity, setEditingActivity] = useState();
  const [activityType, setActivityType] = useState();
  const [typeSelected, setTypeSelected] = useState();
  const [activityToSubmit, setActivityToSubmit] = useState();

  //forms
  const [programForm, setProgramForm] = useState(initialProgramForm);
  //maybe more forms

  const getPrograms = () => {
    HandyGolfAPI.sendRequest("trainer/program/list").then((data) => {
      console.log(data);
      initialProgramsIdsArray(data);
      setProgramList(data);
    });
  };
  const initialProgramsIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.trainer_program_id));
    setProgramListIds(idsArray);
  };
  const getSessions = () => {
    HandyGolfAPI.sendRequest("trainer/session/getSessionsForProgram", {
      trainer_program_id: selectedProgram.trainer_program_id,
    }).then((data) => {
      console.log(data.trainer_sessions);
      initialSessionsIdsArray(data.trainer_sessions);
      setSessionList(data.trainer_sessions);
    });
  };
  const initialSessionsIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.trainer_session_id));
    setSessionListIds(idsArray);
  };
  const getActivities = () => {
    HandyGolfAPI.sendRequest("trainer/activity/getActivitiesForSession", {
      trainer_session_id: selectedSession.trainer_session_id,
    }).then((data) => {
      console.log(data.trainer_activities);
      initialActivitiesIdsArray(data.trainer_activities);
      setActivityList(data.trainer_activities);
    });
  };
  const initialActivitiesIdsArray = (data) => {
    let idsArray = [];
    data.forEach((element) => idsArray.push(element.trainer_activity_id));
    setActivityListIds(idsArray);
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);

  useEffect(() => {
    if (!selectedProgram) {
      getPrograms();
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedProgram && !selectedSession) {
      getSessions();
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedSession) {
      getActivities();
    }
  }, [selectedSession]);
  const handleSessionsOrderChange = (direction, lessonId) => {
    let tempIdsArray = sessionListIds;
    let tempSessions = sessionList;
    let currentIndex = sessionListIds.findIndex((id) => id === lessonId);
    let idElement = sessionListIds[currentIndex];
    let lessonElement = sessionList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempSessions.splice(currentIndex, 1);
      tempSessions.splice(currentIndex - 1, 0, lessonElement);
      setSessionList(tempSessions);
      setSessionListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempSessions.splice(currentIndex, 1);
      tempSessions.splice(currentIndex + 1, 0, lessonElement);
      setSessionList(tempSessions);
      setSessionListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleActivitiesOrderChange = (direction, lessonId) => {
    let tempIdsArray = activityListIds;
    let tempActivities = activityList;
    let currentIndex = activityListIds.findIndex((id) => id === lessonId);
    let idElement = activityListIds[currentIndex];
    let lessonElement = activityList[currentIndex];
    if (direction === "up") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex - 1, 0, idElement);
      tempActivities.splice(currentIndex, 1);
      tempActivities.splice(currentIndex - 1, 0, lessonElement);
      setActivityList(tempActivities);
      setActivityListIds(tempIdsArray);
    }
    if (direction === "down") {
      tempIdsArray.splice(currentIndex, 1);
      tempIdsArray.splice(currentIndex + 1, 0, idElement);
      tempActivities.splice(currentIndex, 1);
      tempActivities.splice(currentIndex + 1, 0, lessonElement);
      setActivityList(tempActivities);
      setActivityListIds(tempIdsArray);
    }
    const timer = setTimeout(() => {
      setSortChanging(false);
    }, 10);

    return () => clearTimeout(timer);
  };
  const handleProgramFormChange = (e) => {
    setProgramForm({
      ...programForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleProgramEditSubmit = (e) => {
    HandyGolfAPI.sendRequest("trainer/program/update", {
      trainer_program_id: selectedProgram.trainer_program_id,
      trainer_program: programForm,
    })
      .then((data) => {
        setSelectedProgram(data.data);
        setEditing(false);
        setProgramForm(initialProgramForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleProgramCreateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/program/create", {
      trainer_program: programForm,
    })
      .then((data) => {
        let newProgram = data.data;
        let programs = programList;
        programs.push(newProgram);
        setProgramList(programs);
        setCreating(false);
        setProgramForm(initialProgramForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const activityTypeConverter = (type) => {
    if (type === 0) {
      return "No Type Selected";
    }
    if (type === 1) {
      return "Drill Explanation";
    }
    if (type === 2) {
      return "Drill Practice";
    }
    if (type === 3) {
      return "Guided Training";
    }
    if (type === 4) {
      return "Lesson";
    }
    if (type === 5) {
      return "Mirror Mode";
    }
    if (type === "No Type Selected") {
      return 0;
    }
    if (type === "Drill Explanation") {
      return 1;
    }
    if (type === "Drill Practice") {
      return 2;
    }
    if (type === "Guided Training") {
      return 3;
    }
    if (type === "Lesson") {
      return 4;
    }
    if (type === "Mirror Mode") {
      return 5;
    }
  };
  //   console.log(activityType);

  const activityCreate = () => {
    let creationObj = {
      ...activityToSubmit,
      trainer_session_id: selectedSession.trainer_session_id,
    };
    HandyGolfAPI.sendRequest("trainer/activity/create", {
      //   trainer_activity_id: datum.trainer_activity_id,
      trainer_activity: creationObj,
    })
      .then((data) => {
        console.log(data);
        let activities = activityList;
        activities.push(data.data);
        setActivityList(activities);
        initialActivitiesIdsArray(activities);
        setCreating(false);
        setActivityToSubmit();
        // secondTable(datum.trainer_session_id, "activities");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const activityUpdate = () => {
    HandyGolfAPI.sendRequest("trainer/activity/update", {
      trainer_activity_id: selectedActivity.trainer_activity_id,
      trainer_activity: activityToSubmit,
    })
      .then((data) => {
        console.log(data);
        let activities = activityList;
        activities.push(data.data);
        setActivityList(activities);
        initialActivitiesIdsArray(activities);
        setEditing(false);
        setActivityToSubmit();
        // secondTable(datum.trainer_session_id, "activities");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   console.log(activityList.length);
  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              <div className="admin-action">
                <div className="admin-action-view">
                  {programList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        HandyGolf Trainer Programs
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Program</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={programList}
                        // tableIds={programListIds}
                        // handleOrderChange={handleProgramsOrderChange}
                        headers={["name", "subtitle"]}
                        setSelectedItem={setSelectedProgram}
                        setEditingItem={setEditingProgram}
                      />
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setProgramForm(initialProgramForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Program</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleProgramCreateSubmit}
                        >
                          <div>
                            <label>Program Name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="name for new program"
                              value={programForm.name}
                              onChange={handleProgramFormChange}
                            />
                          </div>
                          <div>
                            <label>Program Subtitle</label>
                            <input
                              type="text"
                              name="subtitle"
                              placeholder="subtitle for new program"
                              value={programForm.subtitle}
                              onChange={handleProgramFormChange}
                            />
                          </div>
                          <div>
                            <label>Program Frequency</label>
                            <input
                              type="text"
                              name="recommended_frequency"
                              placeholder="recommended_frequency for new program"
                              value={programForm.recommended_frequency}
                              onChange={handleProgramFormChange}
                            />
                          </div>
                          <div>
                            <label>Session Length</label>
                            <input
                              type="number"
                              name="session_length_in_minutes"
                              //   placeholder="session_length_in_minutes for new program"
                              value={programForm.session_length_in_minutes}
                              onChange={handleProgramFormChange}
                            />
                          </div>
                          <div>
                            <label>Program Description</label>
                            <input
                              type="text"
                              name="description"
                              placeholder="description"
                              value={programForm.description}
                              onChange={handleProgramFormChange}
                            />
                          </div>
                          <Button fluid primary>
                            Submit Program
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* PROGRAM DETAILS PAGE */}
                  <div
                    className={
                      !editingProgram
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingProgram(false);
                          setSelectedProgram();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedProgram && (
                      <>
                        <div className="admin-info-half">
                          <div className="admin-info-half__title">
                            Program Details
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                // setLessonForm(selectedLesson);
                                setEditing(true);
                              }}
                            >
                              <i className="material-icons">edit</i>
                              <strong>Edit</strong>
                            </div>
                          </div>
                          <div className="admin-info-half__infobox">
                            <div className="admin-info-half__key-value">
                              <label>Name:</label>
                              <strong>{selectedProgram.name}</strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Subtitle:</label>
                              <strong>{selectedProgram.subtitle}</strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Frequency:</label>
                              <strong>
                                {selectedProgram.recommended_frequency}
                              </strong>
                            </div>
                            <div className="admin-info-half__key-value">
                              <label>Session Length:</label>
                              <strong>
                                {selectedProgram.session_length_in_minutes}{" "}
                                minutes
                              </strong>
                            </div>
                            <div className="admin-info-half__description">
                              <label>Description:</label>
                              <div>
                                {selectedProgram.description !== ""
                                  ? selectedProgram.description
                                  : "Lorum ipsum lorum ipsum flotsam ip jetsom lorem lorem jastom crepsim"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this Program?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "trainer/program/delete",
                                      {
                                        trainer_program_id:
                                          selectedProgram.trainer_program_id,
                                      }
                                    ).then((data) => {
                                      console.log(data);
                                      setEditingProgram(false);
                                      setSelectedProgram();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        {sessionList && (
                          <div className="admin-table-half">
                            <div className="admin-table-half__title">
                              Sessions in this Program
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  setCreating(true);
                                }}
                              >
                                <i className="material-icons">add</i>
                                <strong>New Session</strong>
                              </div>
                            </div>
                            <AdminTable
                              tableData={sessionList}
                              tableIds={sessionListIds}
                              handleOrderChange={handleSessionsOrderChange}
                              headers={
                                openSorting
                                  ? ["", "session_number"]
                                  : ["session_number"]
                              }
                              setSortChanging={setSortChanging}
                              idType={"trainer_session_id"}
                              setSelectedItem={setSelectedSession}
                              setEditingItem={setEditingSession}
                            />
                            {openSorting ? (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "trainer/session/renumberSessionsForProgram",
                                        {
                                          trainer_program_id:
                                            selectedProgram.trainer_program_id,

                                          trainer_session_ids: sessionListIds,
                                        }
                                      ).then((data) => {
                                        getSessions();
                                      });
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Save Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(true);
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Set Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={
                            !creating
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCreating(false);
                                // setSessionForm(initialSessionForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                New Session for: <br></br> '
                                {selectedProgram.name}'
                              </h2>
                              <form className="admin-form">
                                <Button fluid primary>
                                  Add New Session
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setProgramForm(initialProgramForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>Edit Program Form</h2>
                              <form
                                className="admin-form"
                                onSubmit={handleProgramEditSubmit}
                              >
                                <div>
                                  <label>Program Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedProgram.name}
                                    value={programForm.name}
                                    onChange={handleProgramFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Program Subtitle</label>
                                  <input
                                    type="text"
                                    name="subtitle"
                                    placeholder={selectedProgram.subtitle}
                                    value={programForm.subtitle}
                                    onChange={handleProgramFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Program Frequency</label>
                                  <input
                                    type="text"
                                    name="recommended_frequency"
                                    placeholder={
                                      selectedProgram.recommended_frequency
                                    }
                                    value={programForm.recommended_frequency}
                                    onChange={handleProgramFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Session Length</label>
                                  <input
                                    type="number"
                                    name="session_length_in_minutes"
                                    placeholder={
                                      selectedProgram.session_length_in_minutes
                                    }
                                    value={
                                      programForm.session_length_in_minutes
                                    }
                                    onChange={handleProgramFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Program Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder={selectedProgram.description}
                                    value={programForm.description}
                                    onChange={handleProgramFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Program
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      !editingSession
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingSession(false);
                          setSelectedSession();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedSession && (
                      <>
                        <div className="admin-info-half">
                          <div className="admin-info-half__title">
                            Session Details
                            {/* <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                // setLessonForm(selectedLesson);
                                setEditing(true);
                              }}
                            >
                              <i className="material-icons">edit</i>
                              <strong>Edit</strong>
                            </div> */}
                          </div>
                          <div className="admin-info-half__infobox">
                            <div className="admin-info-half__key-value">
                              <label>Session Number</label>
                              <strong>{selectedSession.session_number}</strong>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "40px",
                              }}
                            >
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you SURE you want to PERMANENTLY DELETE this Session?"
                                    )
                                  ) {
                                    HandyGolfAPI.sendRequest(
                                      "trainer/session/delete",
                                      {
                                        trainer_session_id:
                                          selectedSession.trainer_session_id,
                                      }
                                    ).then((data) => {
                                      console.log(data);
                                      setEditingSession(false);
                                      setSelectedSession();
                                    });
                                  }
                                }}
                              >
                                <i className="material-icons">delete</i>
                                <strong>Delete</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                        {activityList && (
                          <div className="admin-table-half">
                            <div className="admin-table-half__title">
                              Activities in Selected Session
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  // setEditing(true);
                                  setCreating(true);
                                }}
                              >
                                <i className="material-icons">add</i>
                                <strong>New Activity</strong>
                              </div>
                            </div>
                            <AdminTable
                              tableData={activityList}
                              tableIds={activityListIds}
                              handleOrderChange={handleActivitiesOrderChange}
                              headers={
                                openSorting
                                  ? ["", "sort_order", "name"]
                                  : ["name", "type"]
                              }
                              setSortChanging={setSortChanging}
                              idType={"trainer_activity_id"}
                              setSelectedItem={setSelectedActivity}
                              setEditingItem={setEditingActivity}
                              typeConverter={activityTypeConverter}
                            />
                            {openSorting ? (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(false);
                                    }}
                                  >
                                    <strong>Cancel</strong>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      HandyGolfAPI.sendRequest(
                                        "trainer/activity/reorderActivitiesForSession",
                                        {
                                          trainer_session_id:
                                            selectedSession.trainer_session_id,

                                          trainer_activity_ids: activityListIds,
                                        }
                                      ).then((data) => {
                                        getActivities();
                                      });
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Save Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "40px",
                                  }}
                                >
                                  <div
                                    className="admin-edit-item-button"
                                    onClick={() => {
                                      setOpenSorting(true);
                                    }}
                                  >
                                    <i className="material-icons">swap_vert</i>
                                    <strong>Set Sort Order</strong>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          className={
                            !creating
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setCreating(false);
                                setActivityToSubmit();
                                // setSessionForm(initialSessionForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              {/* <h2>New Activity</h2> */}
                              <ActivityPicker
                                creating={creating}
                                setActivityToSubmit={setActivityToSubmit}
                                activityToSubmit={activityToSubmit}
                              />
                              {activityToSubmit && (
                                <Button
                                  secondary
                                  fluid
                                  onClick={() => {
                                    activityCreate();
                                    setCreating(false);
                                  }}
                                >
                                  Submit Activity
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div
                    className={
                      !editingActivity
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingActivity(false);
                          setActivityToSubmit();
                          // setSessionForm(initialSessionForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedActivity && (
                      <div className="admin-info-half">
                        <div className="admin-info-half__title">
                          Selected Activity
                          <div
                            className="admin-edit-item-button"
                            onClick={() => {
                              // setLessonForm(selectedLesson);
                              // setEditing(true);
                              setEditing(true);
                            }}
                          >
                            <i className="material-icons">edit</i>
                            <strong>Edit</strong>
                          </div>
                        </div>
                        <div className="admin-info-half__infobox">
                          <div className="admin-info-half__key-value">
                            <label>Name:</label>
                            <strong>{selectedActivity.name}</strong>
                          </div>
                        </div>
                        <div>
                          <div
                            style={{
                              position: "relative",
                              width: "100%",
                              height: "40px",
                            }}
                          >
                            <div
                              className="admin-edit-item-button"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    "Are you SURE you want to PERMANENTLY DELETE this Activity?"
                                  )
                                ) {
                                  HandyGolfAPI.sendRequest(
                                    "trainer/activity/delete",
                                    {
                                      trainer_activity_id:
                                        selectedActivity.trainer_activity_id,
                                    }
                                  ).then((data) => {
                                    console.log(data);
                                    setEditingActivity(false);
                                    setSelectedActivity();
                                  });
                                }
                              }}
                            >
                              <i className="material-icons">delete</i>
                              <strong>Delete</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div
                      className={
                        !editing
                          ? "admin-action-edit-screen"
                          : "admin-action-edit-screen admin-action-edit-screen--open"
                      }
                    >
                      <div className="admin-action-view__edit-button">
                        <Button
                          primary
                          iconLeading="expand_more"
                          onClick={() => {
                            setEditing(false);
                            setActivityToSubmit();
                            // setSessionForm(initialSessionForm);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          {/* <h2>New Activity</h2> */}
                          <ActivityPicker
                            creating={creating}
                            prevActivity={selectedActivity}
                            setActivityToSubmit={setActivityToSubmit}
                            activityToSubmit={activityToSubmit}
                          />
                          {activityToSubmit && (
                            <Button
                              secondary
                              fluid
                              onClick={() => {
                                activityUpdate();
                                setCreating(false);
                              }}
                            >
                              Submit Activity
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
