import React, { useEffect, useState } from "react";
import HandyGolfAPI, { session } from "../lib/HandyGolfAPI";
import { AdminTable, Uploader } from "./index";
import { Button, AdminNav } from "../components";
import { useUser } from "../providers";

const initialEquipmentForm = { name: "", code: "", description: "" };
export function AdminEquipment() {
  const [user, setContextUser, isLoadingUser, adminMode, enterAdminMode] =
    useUser();
  //see if we dont need these two V
  // const [loading, setLoading] = useState(true);
  // const [sortChanging, setSortChanging] = useState();
  const [editing, setEditing] = useState();
  const [creating, setCreating] = useState();
  const [deleting, setDeleting] = useState();
  const [uploading, setUploading] = useState();
  //equipment
  const [equipmentList, setEquipmentList] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [editingEquipment, setEditingEquipment] = useState();
  //forms
  const [equipmentForm, setEquipmentForm] = useState(initialEquipmentForm);

  const getEquipment = () => {
    HandyGolfAPI.sendRequest("trainer/equipment/list").then((data) => {
      console.log(data);
      setEquipmentList(data);
    });
  };

  useEffect(() => {
    if (!adminMode) {
      enterAdminMode();
    }
  }, [adminMode]);
  useEffect(() => {
    if (!selectedEquipment) {
      getEquipment();
    }
  }, [selectedEquipment]);

  const handleEquipmentFormChange = (e) => {
    setEquipmentForm({
      ...equipmentForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleEquipmentCreateSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/equipment/create", {
      trainer_equipment: equipmentForm,
    })
      .then((data) => {
        let newEquip = data.data;
        let equips = equipmentList;
        equips.push(newEquip);
        setEquipmentList(equips);
        setCreating(false);
        setEquipmentForm(initialEquipmentForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleEquipmentEditSubmit = (e) => {
    e.preventDefault();
    HandyGolfAPI.sendRequest("trainer/equipment/update", {
      trainer_equipment_id: selectedEquipment.trainer_equipment_id,
      trainer_equipment: equipmentForm,
    })
      .then((data) => {
        setSelectedEquipment(data.data);
        setEditing(false);
        setEquipmentForm(initialEquipmentForm);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <AdminNav />
      <div className="container no-scroll-container">
        <div className="container__content no-scroll-container">
          <div className="pad-box">
            <div className="admin-action-container">
              <div className="admin-action">
                <div className="admin-action-view">
                  {equipmentList && (
                    <div className="admin-table-half">
                      <div className="admin-table-half__title">
                        HandyGolf Trainer Equipment
                        <div
                          className="admin-edit-item-button"
                          onClick={() => {
                            // setLessonForm();
                            setCreating(true);
                          }}
                        >
                          <i className="material-icons">add</i>
                          <strong>New Equipment</strong>
                        </div>
                      </div>
                      <AdminTable
                        tableData={equipmentList}
                        headers={["name", "description"]}
                        setSelectedItem={setSelectedEquipment}
                        setEditingItem={setEditingEquipment}
                      />
                    </div>
                  )}
                  <div
                    className={
                      !creating
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setCreating(false);
                          setEquipmentForm(initialEquipmentForm);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <h2>Create New Equipment</h2>
                        <form
                          className="admin-form"
                          onSubmit={handleEquipmentCreateSubmit}
                        >
                          <div>
                            <label>Name</label>
                            <input
                              type="text"
                              name="name"
                              placeholder="new equipment name"
                              value={equipmentForm.name}
                              onChange={handleEquipmentFormChange}
                            />
                          </div>
                          <div>
                            <label>Description</label>
                            <input
                              type="text"
                              name="description"
                              placeholder="new equipment description"
                              value={equipmentForm.description}
                              onChange={handleEquipmentFormChange}
                            />
                          </div>
                          <div>
                            <label>Code</label>
                            <input
                              type="text"
                              name="code"
                              placeholder="new equipment code"
                              value={equipmentForm.code}
                              onChange={handleEquipmentFormChange}
                            />
                          </div>
                          <Button fluid primary>
                            Submit Equipment
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* EQUIPMENT DETAILS PAGE */}
                  <div
                    className={
                      !editingEquipment
                        ? "admin-action-edit-screen"
                        : "admin-action-edit-screen admin-action-edit-screen--open"
                    }
                  >
                    <div className="admin-action-view__edit-button">
                      <Button
                        primary
                        iconLeading="expand_more"
                        onClick={() => {
                          setEditingEquipment(false);
                          setEditingEquipment();
                        }}
                      >
                        Close
                      </Button>
                    </div>
                    {selectedEquipment && (
                      <>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            className="admin-info-half"
                            style={{ maxWidth: "460px" }}
                          >
                            <div className="admin-info-half__title">
                              Equipment Details
                              <div
                                className="admin-edit-item-button"
                                onClick={() => {
                                  // setLessonForm(selectedLesson);
                                  setEditing(true);
                                }}
                              >
                                <i className="material-icons">edit</i>
                                <strong>Edit</strong>
                              </div>
                            </div>
                            <div className="admin-info-half__infobox">
                              <div className="admin-info-half__key-value">
                                <label>Name</label>
                                <strong>{selectedEquipment.name}</strong>
                              </div>
                              <div className="admin-info-half__key-value">
                                <label>Code</label>
                                <strong>{selectedEquipment.code}</strong>
                              </div>
                              <div className="admin-info-half__description">
                                <label>Description:</label>
                                <div>
                                  {selectedEquipment.description !== ""
                                    ? selectedEquipment.description
                                    : "Lorum ipsum lorum ipsum flo"}
                                </div>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "40px",
                                }}
                              >
                                <div
                                  className="admin-edit-item-button"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you SURE you want to PERMANENTLY DELETE this Equipment and all of its contents?"
                                      )
                                    ) {
                                      HandyGolfAPI.sendRequest(
                                        "trainer/equipment/delete",
                                        {
                                          trainer_equipment_id:
                                            selectedEquipment.trainer_equipment_id,
                                        }
                                      ).then((data) => {
                                        console.log(data);
                                        setEditingEquipment(false);
                                        setSelectedEquipment();
                                      });
                                    }
                                  }}
                                >
                                  <i className="material-icons">delete</i>
                                  <strong>Delete</strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            !editing
                              ? "admin-action-edit-screen"
                              : "admin-action-edit-screen admin-action-edit-screen--open"
                          }
                        >
                          <div className="admin-action-view__edit-button">
                            <Button
                              primary
                              iconLeading="expand_more"
                              onClick={() => {
                                setEditing(false);
                                setEquipmentForm(initialEquipmentForm);
                              }}
                            >
                              Close
                            </Button>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <h2>
                                Edit Equipment:<br></br>'
                                {selectedEquipment.name}'
                              </h2>
                              <form
                                className="admin-form"
                                onSubmit={handleEquipmentEditSubmit}
                              >
                                <div>
                                  <label>Name</label>
                                  <input
                                    type="text"
                                    name="name"
                                    placeholder={selectedEquipment.name}
                                    value={equipmentForm.name}
                                    onChange={handleEquipmentFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Description</label>
                                  <input
                                    type="text"
                                    name="description"
                                    placeholder={selectedEquipment.description}
                                    value={equipmentForm.description}
                                    onChange={handleEquipmentFormChange}
                                  />
                                </div>
                                <div>
                                  <label>Code</label>
                                  <input
                                    type="text"
                                    name="code"
                                    placeholder={selectedEquipment.code}
                                    value={equipmentForm.code}
                                    onChange={handleEquipmentFormChange}
                                  />
                                </div>
                                <Button fluid primary>
                                  Submit Equipment
                                </Button>
                              </form>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
