import React, { useEffect, useState, useMemo } from "react";
import { Button } from "../components";

export function AdminTable({
  tableData,
  tableIds,
  handleOrderChange,
  headers,
  setSortChanging,
  idType,
  setSelectedItem,
  setEditingItem,
  typeConverter,
}) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const handleSort = (key) => {
    let direction = sortConfig.direction === "asc" ? "desc" : "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };
  const sortedData = useMemo(() => {
    if (sortConfig.key) {
      return [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return tableData;
  }, [tableData, sortConfig]);
  return (
    <div>
      <div className="admin-table-wrapper">
        <table className="admin-table">
          <thead>
            <tr>
              {headers.map((header, idx) => {
                return (
                  <th
                    key={idx}
                    onClick={() => handleSort(header)}
                    style={
                      header === ""
                        ? {
                            cursor: "pointer",
                            width: "100px",
                            paddingLeft: "0",
                          }
                        : { cursor: "pointer" }
                    }
                  >
                    {/* {handleHeaderStrings(header)} */}
                    {header}
                    <span
                      style={
                        sortConfig.key === header
                          ? { opacity: "1" }
                          : { opacity: "0" }
                      }
                    >
                      {sortConfig.direction === "asc" ? " ▼" : " ▲"}
                    </span>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((item, rowIdx) => {
              return (
                <tr
                  key={rowIdx}

                  // style={
                  //     selectedRow === rowIdx ? { backgroundColor: "#0b1120" } : {}
                  //   }

                  //   onClick={() => {

                  //     setSelectedRow(rowIdx);

                  //     // if (secondTable) {
                  //     //   secondTableActionSetter(item);
                  //     // } else {
                  //     //   actionSetter(item);
                  //     // }

                  //   }}
                >
                  {headers.map((header, itemIdx) => {
                    return (
                      <td
                        key={itemIdx}
                        style={header === "" ? { paddingLeft: "0" } : {}}
                      >
                        {header === "" ? (
                          <div>
                            <div className="admin-arrows">
                              {tableIds[0] === item[idType] ? (
                                <i
                                  className="material-icons"
                                  style={{ opacity: ".5" }}
                                >
                                  expand_less
                                </i>
                              ) : (
                                <i
                                  className="material-icons can-click"
                                  onClick={() => {
                                    setSortChanging(true);
                                    handleOrderChange(
                                      "up",
                                      item[idType]
                                      //   item.session_number
                                    );
                                  }}
                                >
                                  expand_less
                                </i>
                              )}
                              {tableIds[tableIds.length - 1] ===
                              item[idType] ? (
                                <i
                                  className="material-icons"
                                  style={{ opacity: ".5" }}
                                >
                                  keyboard_arrow_down
                                </i>
                              ) : (
                                <i
                                  className="material-icons can-click"
                                  onClick={() => {
                                    setSortChanging(true);
                                    handleOrderChange(
                                      "down",
                                      item[idType]
                                      //   item.session_number
                                    );
                                  }}
                                >
                                  keyboard_arrow_down
                                </i>
                              )}
                            </div>
                          </div>
                        ) : header === "profile_picture_url" ? (
                          <div className="table-profile-pic">
                            <img
                              src={
                                item[header] !== ""
                                  ? item[header]
                                  : "/img/no-profile-pic.png"
                              }
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setSelectedItem(item);
                              setEditingItem(true);
                            }}
                          >
                            {header === "type"
                              ? typeConverter(item[header])
                              : item[header]}
                          </div>
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        {/* <Button
          primary
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span>
          Page
          {currentPage} 
          of
          {totalPages}
        </span>
        <Button
          primary
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
        >
          Next
        </Button> */}
      </div>
    </div>
  );
}
